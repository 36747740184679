
var pdf = {
    getPdfTemplate: async function (getters, type) {
        const formData = new FormData();
        formData.append('method', "export_getTemplate");
        formData.append('type', type);
        const pdf = await fetch(getters.path_data, {
            method: "POST",
            body: formData
        }).then(result => result.arrayBuffer())
        const pdfDoc = await PDFDocument.load(pdf)
        return pdfDoc
    },
    getManufacturerInformation: async (getters) => {
        const formData = new FormData();
        formData.append('method', "export_getManufacturerInformation");
        return fetch(getters.path_data, {
            method: "POST",
            body: formData
        }).then(result => result.json())
    },
    // getTemplateInformation: async function(getters, type) {
    //     const formData = new FormData();
    //     formData.append('method', "export_getTemplateInformation");
    //     formData.append('method', type);
    //     const pdf = await fetch(getters.path_data, {
    //         method: "POST",
    //         body: formData
    //     }).then(result => result.arrayBuffer())
    //     const pdfDoc = await PDFDocument.load(pdf)
    //     return pdfDoc
    // },
    savePdf: async function (pdfDoc) {
        const pdfBytes = await pdfDoc.save()
        saveByteArray("Sample Report", base64ToArrayBuffer(pdfBytes));
    },

    createPdf: async function(getters, type) {
        let pdfDoc = await pdf.getPdfTemplate(getters, type)//getPDF(await pdf.getTemplateURL())
        const pages = pdfDoc.getPages()
        const page = pages[0]



        switch (type) {
            case "offer":
                await pdf.createOfferPdf(getters, page, pdfDoc)
                break;
        
            default:
                break;
        }

        pdf.savePdf(pdfDoc)

        // const form = pdfDoc.getForm()
        // const textField = form.createTextField('name')
        // textField.setText('Julian Meinhold')
        // textField.addToPage(page, {
        //     x: 326,
        //     y: 817.9-234,
        //     width: 100,
        //     height: 24,
        //     textColor: rgb(0, 0, 0),
        //     // backgroundColor: rgb(0, 1, 0),
        //     // borderColor: rgb(0, 0, 1),
        //     // borderWidth: 2,
        //     // rotate: degrees(90),
        //     // font: "Arial",
        // })



        // const pdfDoc = await PDFDocument.create()
        // const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        // const page = pdfDoc.addPage()
        // const { width, height } = page.getSize()
        // console.log(width);
        // const fontSize = 30
        // page.drawText('Creating PDFs in JavaScript is awesome!', {
        //     x: 50,
        //     y: height - 4 * fontSize,
        //     size: fontSize,
        //     font: timesRomanFont,
        //     color: rgb(0, 0.53, 0.71),
        // })

        // const pdfBytes = await pdfDoc.save()
        // console.log(pdfBytes);
    },
    createOfferPdf: async (getters, page, pdfDoc) => {
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
        let information = getters.customerInformation
        let manufacturer = await pdf.getManufacturerInformation(getters) 
        let calcInfo = getters.calcInfo
        let lineSpacing = 16
        let left = 70
        let right = 325
        console.log(manufacturer);
        console.log(information, calcInfo);
        
        //  Manufacturer
        page.drawText(manufacturer.name, {
            x: right,
            y: 833 - 140,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Customer street + housenumber
        page.drawText(manufacturer.street + " " + manufacturer.housenumber, {
            x: right,
            y: 833 - 140 - 1 * lineSpacing,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Customer zipcodecity
        page.drawText(manufacturer.zipcodecity, {
            x: right,
            y: 833 - 140 - 2 * lineSpacing,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Tel
        page.drawText(manufacturer.tel, {
            x: right,
            y: 833 - 140 - 4 * lineSpacing,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Mail
        page.drawText(manufacturer.email, {
            x: right,
            y: 833 - 140 - 5 * lineSpacing,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Date
        const d = new Date();
        page.drawText(`${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`, {
            x: right,
            y: 833 - 140 - 6 * lineSpacing,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Customer
        //  Customer Name
        page.drawText(information.name, {
            x: left,
            y: 833 - 140,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Customer street + housenumber
        page.drawText(information.street + " " + information.housenumber, {
            x: left,
            y: 833 - 140 - 1 * lineSpacing,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Customer street + housenumber
        page.drawText(information.zipcodecity, {
            x: left,
            y: 833 - 140 - 2 * lineSpacing,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Customer zipcodecity
        page.drawText(information.lettertext.replace('\n\n', '\n'), {
            x: left,
            y: 833 - 330,
            size: 12,
            lineHeight: 30,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        })
        //  Anschreiben
        //  Bezeichnung
        page.drawText("Teil", {
            x: left,
            y: 833 - 330-4*lineSpacing,
            size: 12,
            lineHeight: 30,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        //  Menge
        page.drawText("Menge", {
            x: left+200,
            y: 833 - 330 - 4 * lineSpacing,
            size: 12,
            lineHeight: 30,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        //  Stückpreis
        page.drawText("Stückpreis", {
            x: left+300,
            y: 833 - 330 - 4 * lineSpacing,
            size: 12,
            lineHeight: 30,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        //  Gesamt
        page.drawText("Gesamt", {
            x: left+400,
            y: 833 - 330 - 4 * lineSpacing,
            size: 12,
            lineHeight: 30,
            font: helveticaBold,
            color: rgb(0, 0, 0),
        })
        //  All Parts
        var i = 0
        for (const ls in getters.lotsize_prices) {
            i++
            //  Bezeichnung
            page.drawText(getters.information.part, {
                x: left,
                y: 833 - 330 - (4+i) * (lineSpacing+4),
                size: 12,
                lineHeight: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })
            //  Menge
            page.drawText(ls, {
                x: left + 200,
                y: 833 - 330 - (4+i) * (lineSpacing+4),
                size: 12,
                lineHeight: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })
            //  Stückpreis
            page.drawText(getters.lotsize_prices[ls]+"€", {
                x: left + 300,
                y: 833 - 330 - (4+i) * (lineSpacing+4),
                size: 12,
                lineHeight: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })
            //  Gesamt
            page.drawText((getters.lotsize_prices[ls] * ls).toFixed(2) + "€", {
                x: left + 400,
                y: 833 - 330 - (4+i) * (lineSpacing+4),
                size: 12,
                lineHeight: 30,
                font: helveticaFont,
                color: rgb(0, 0, 0),
            })
        }

        return page
    }
}
function base64ToArrayBuffer(binaryString) {
    var bytes = new Uint8Array(binaryString);
    for (var i = 0; i < binaryString; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}
function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: "application/pdf" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
};

import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'




import Vue from "vue";
const exp = {
    mutations: {
        setCustomerInformation(state, [rootState, key, value]) {
            Vue.set(rootState.calculation.information.customer, key, value)
        },
        exportPDF(state, type) {
            pdf.createPdf(this.getters, type)
        }
    },
    actions: {

    },
    getters: {
        customerInformation: (state, getters, rootState) => {
            if (typeof rootState.calculation.information.customer != "object") rootState.calculation.information.customer = {}
            if (!rootState.calculation.information.customer) Vue.set(rootState.calculation.information, "customer", {})
            return rootState.calculation.information.customer
        }
    }
}
export default exp