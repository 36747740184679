// var path = ""
// if (document.location.href.match('https://primecalc.de')) {
//     path = ()=>'https://primecalc.de/bin/' 
// }
// else {
//     path = () =>'https://development.primecalc.de/bin/' 
// }

const path = {
    getters: {
        path() {
            return document.location.href.match('https://primecalc.de') ? 'https://primecalc.de/bin/' : 'https://development.primecalc.de/bin/';
        },
        path_data() {
            return document.location.href.match('https://primecalc.de') ? 'https://primecalc.de/bin/data.php' : 'https://development.primecalc.de/bin/data.php';
        },
        path_account() {
            return document.location.href.match('https://primecalc.de') ? 'https://primecalc.de/bin/account.php' : 'https://development.primecalc.de/bin/account.php';
        }
    }
}

export default path