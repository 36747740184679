<template>
    <div>
        <!-- <radial-hole></radial-hole> -->
        <!-- <thread></thread> -->
        <!-- <keyway></keyway> -->
        <!-- <shapemilling></shapemilling> -->
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>