<template>
    <div id="box">
        <div id="subProcessOptions" class="card">
            <h3 class="card_title">Nötige Prozessparameter</h3>
            <div class="padding" id="gridContainer">
                <img id="keywayImage" src="https://development.primecalc.de/assets/images/keyway.png" alt="">
                <div>
                    <p><b>Passfedernut</b></p>
                    <div class="flex padding_small radius" v-for="(t, i) in keyways" :key="i">
                        <p>Länge:</p>
                        <input type="number" min="1" v-model="t.length">
                        <p>mm</p>
                        <p>Breite:</p>
                        <input type="number" min="1" v-model="t.width">
                        <p>mm</p>
                        <p>Tiefe:</p>
                        <input type="number" min="1" v-model="t.depth">
                        <p>mm</p>
                        <!-- <p>Norm:</p>
                        <select name="" id="" v-model="t.standard">
                            <option :value="std" v-for="(std, s) in standards" :key="s">{{std}}</option>
                        </select> -->
                    </div>
                    <div id="keywayAdd" class="dark padding_small pointer radius flex fitContent" @click="addKeyway()">
                        <img style="height: 1.5em; width: 1.5em;" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                        <p class="marginAuto">Passfedernut hinzufügen</p>
                    </div>
                </div>
                <p id="next" @click="next()" class="blue padding_small radius fitContent pointer" style="margin: .5em 0 0 auto;">weiter</p>
            </div>
        </div>

        <!-- <div id="manufacturingtime" class="card">
            <h3 class="card_title padding_small dark radius">Prozess genauer definieren</h3>
            <div class="padding">
                <component :is="subProcess_selected+'-evaluation'"></component>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            keyways: [],
            standards: ["-","M2"],
            // standardsWithDiameters: ["M2","M2,5","M3","M4","M5","M6","M8","M10","M12","M14","M16","M18","M20","M22","M24"]
        }
    },
    methods: {
        addKeyway() {
            this.keyways.push({length: 22, width: 10, depth: 9, standard: null})
        },
        next() {
            console.log("next");
        }
    },
    computed: {
    }
}
</script>

<style scoped>
    #box>.card:first-child{margin-bottom: 2em;}
    #box>.card{width: fit-content;}
    .card{
        transition: .25s;
        opacity: .85;
    }
    .card:hover{opacity: 1;}
    .flex{margin: 4px 0;}
    .flex>input{width: 4em;}
    .flex>p:nth-child(3n){margin-right: 1em;}
    .flex:nth-child(even){background-color: rgb(247, 247, 247);}
    #gridContainer{
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 2em;
    }
    #keywayImage{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 12em;
        width: 12em;
        margin: 1em auto;
    }
    #next{
        position: absolute;
        right: .5em;
        bottom: .5em;
    }
    .diameter{
        transform-origin: center;
        transform: scale(1.5);
        margin-right: 5px;
    }
    select{background-color: rgba(0, 0, 0, 0);}
</style>