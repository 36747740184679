<template>
    <div>
        <div id="loginBox" v-if="authenticated != 'signedIn'" @click="checkLogin()">
            <amplify-authenticator></amplify-authenticator>
        </div>
        <!-- <login v-if="authenticated != 'signedIn'"></login> -->
        <!-- <div >
            <input type="username" v-model="username">
            <input type="password" v-model="password">
            <p @click="login()">Login</p>
        </div> -->
        <!-- <amplify-sign-in v-if="!authenticated"></amplify-sign-in> -->
        <router-view v-else></router-view>
    </div>
</template>

<script>

import { AmplifyEventBus } from "aws-amplify-vue"; 
export default {
    name: "App",
    metaInfo() {
        return { 
            title: "PrimeCalc - Kalkulation",
            meta: [
                // { name: 'description', content:  'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'},
                // { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
                // { property: 'og:site_name', content: 'Epiloge'},
                // {property: 'og:type', content: 'website'},    
                // {name: 'robots', content: 'index,follow'} 
            ]
        }
    },
    created() {
        AmplifyEventBus.$on('authState', user => {
            if (user == "signedIn") {
                this.checkLogin()
            }
        });
        // AmplifyEventBus.signedIn = (value)=>{
        //     console.log(value);
        // }

        this.getDict()
        this.checkLogin()
    },
    methods: {        
        getDict() {
            const formData = new FormData();
            formData.append('method', "dict_get");
            fetch(this.$store.getters.path_data, {
                method: "POST",
                body: formData
            }).then(result => {
                return result.json()
            }).then(result => {
                this.$store.commit("setValue", ["dict", result])
            })
        },
        checkLogin() {
            this.$Amplify.Auth.currentAuthenticatedUser().then((response)=>{
                if(response.username) this.$store.commit("setLoggedIn", ["loggedIn", "signedIn"])     
                this.$store.state.username = response.username
                this.$store.dispatch("loadCalculation")
            })
        }
    },
    computed: {
        authenticated() {
            return this.$store.getters.authenticated
        }
    }
}
</script>

<style>
    @import 'C:/Users/email/Desktop/primecalc/src/assets/css/main.css';
    @import 'https://fonts.googleapis.com/css?family=Montserrat:300,500,700';
</style>
<style scoped>
    #loginBox{
        position: absolute;
        top: 50%;
        left: 50%;   
        transform: translate(-50%, -50%);
    }
</style>