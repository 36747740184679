<template>
    <div>
        <div class="card">
            <h3 class="card_title dark">
                <!-- <toggle 
                        :value="ui.machines.setup_toggled"
                        @toggle="ui.machines.setup_toggled = !$event"></toggle> -->
                Rüstzeit</h3>
            <div class="card_content padding">
                <!-- <value-input 
                :values="calculation" 
                :infoobjects="ui.setup"
                ></value-input> -->
                <br>
                <p class="textspacing">Auswertung:</p>
                <div class="textgrid indent">
                    <p>Rüstzeit:</p>
                    <p>{{setuptime_total}} min</p>
                    <p>Werkzeugtausch:</p>
                    <p>{{setuptime_toolexchangetime}} min</p>
                    <p>Rüstkosten:</p>
                    <p>{{setuptime_cost}}€</p>                                                        
                </div>
            </div>
        </div>     
    </div>
</template>

<script>
export default {
    name: "Setuptime",
    data() {
        return {
            setuptime_total: 0,
            setuptime_toolexchangetime: 0,
            setuptime_cost: 0,
        }
    },
    created() {
        console.log("kp");
    }
}
</script>

<style>

</style>