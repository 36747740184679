import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from 'vue-meta'

import pcMenu from "./components/Menu.vue";
import pcSummary from "./components/Summary.vue";
import login from "./components/Login.vue";
import setuptime from "./components/Setuptime.vue";
import loading from "./components/Loading.vue";
import search from "./components/Search.vue";
import searchlist from "./components/Searchlist.vue";
import counter from "./components/Counter.vue";
import indicator from "./components/Indicator.vue";
import pcInput from "./components/PcInput.vue";
Vue.component('pc-menu', pcMenu);
Vue.component('pc-summary', pcSummary);
Vue.component('login', login);
Vue.component('setuptime', setuptime);
Vue.component('loading', loading);
Vue.component('search', search);
Vue.component('searchlist', searchlist);
Vue.component('counter', counter);
Vue.component('indicator', indicator);
Vue.component('pc-input', pcInput);

import File3d from "./components/processes/turning/File3d.vue";
import FileDrawing from "./components/processes/turning/FileDrawing.vue";
import Contour from "./components/processes/turning/Contour.vue";
import ExtraFeatures from "./components/processes/turning/ExtraFeatures.vue";
import Grooves from "./components/processes/turning/Grooves.vue";
import Inside from "./components/processes/turning/Inside.vue";
import InsideComplexity from "./components/processes/turning/InsideComplexity.vue";
import Dimensions from "./components/processes/turning/Dimensions.vue";
import Sides from "./components/processes/turning/Sides.vue";
import RadialHole from "./components/processes/turning/RadialHole.vue";
import Thread from "./components/processes/turning/Thread.vue";
import Keyway from "./components/processes/turning/Keyway.vue";
import Shapemilling from "./components/processes/turning/Shapemilling.vue";
Vue.component('step', File3d);
Vue.component('pdf', FileDrawing);
Vue.component('dimensions',Dimensions);
Vue.component('contour',Contour);
Vue.component('extra-features',ExtraFeatures);
Vue.component('grooves',Grooves);
Vue.component('inside',Inside);
Vue.component('inside-complexity',InsideComplexity);
Vue.component('sides',Sides);
Vue.component('radial-hole',RadialHole);
Vue.component('thread', Thread);
Vue.component('keyway', Keyway);
Vue.component('shapemilling', Shapemilling);


import materialDatabase from "./components/material/Database.vue";
Vue.component('material-database', materialDatabase);
// import AvailableMachines from "./components/machines/AvailableMachines.vue";
// import NewMachine from "./components/machines/NewMachine.vue";
// Vue.component('available-machines', AvailableMachines);
// Vue.component('new-machine', NewMachine);



//////////AWS
import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue"; 
import { I18n } from 'aws-amplify';
import awsmobile from "@/aws-exports";

Amplify.configure(awsmobile);
Vue.use(AmplifyPlugin, AmplifyModules);

I18n.setLanguage('de');
I18n.putVocabularies({
    "de": {
        'Enter your Nutzername': "Geben Sie Ihren Nutzername ein",
        'Sign In': "Anmelden",
        "Sign in to your account": "Anmelden",
        "Forgot your password?": "Passwort vergessen?",
        'Confirm': "Bestätigen",
        'Back to Sign In': "Anmelden",
        'Confirmation Code': "Bestätigungscode",
        'Resend Code': "Code erneut senden",
        'Lost your code? ': "Code verloren?",
        'Have an account? ': "",
        'Confirm Sign Up': "Registrierung bestätigen",
        'Valid username not received.': "Keinen bestätigten Nutzernamen erhalten",
        'New Password': "Neues Passwort",
        'Send Code': "Code senden",
        'Reset your password': "Passwort zurücksetzen",
        'Submit': "Absenden",
        'Phone number': "Telefonnummer",
        'Enter new password': "Neues Passwort eingeben",
        'Enter your password': "Passwort eingeben",
        'Forgot your password? ': "Passwort vergessen?",
        'Reset password': "Passwort zurücksetzen",
        'No account? ': " ",
        'Create account': " ",
        'Sign Out': "Abmelden",
        'Enter your email': "Geben Sie Ihre EMail Adresse ein.",
        'Username cannot be empty': "Geben Sie einen Nutzernamen an",
        "Incorrect username or password.": "Nutzername oder Passwort falsch",
        'Custom auth lambda trigger is not configured for the user pool.': ""
    }
});

// async function testAPI() {
//     // return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((user) => {
//     //     let jwtToken = user.signInUserSession.idToken.jwtToken;
//     //     const options = {
//     //         mode: "no-cors",
//     //         headers: {
//     //             Authorization: jwtToken
//     //         }
//     //     }
//     //     Vue.prototype.$Amplify.API.post('primecalc_clientAccess', '/', options).then(response => {
//     //         // this.setState({
//     //         //     xyz: response.message
//     //         // });
//     //         return response;
//     //     }).catch(error => {
//     //         console.log(error.response)
//     //     });

//     // });



// }


// Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then(response=>{
//     console.log(response);
// })
//////////AWS




Vue.config.productionTip = true;
Vue.use(VueMeta)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
