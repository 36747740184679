
import Vue from "vue";
const machines = {
    state: {
        catalogue_selected: "",
        machineInformation: {},
        machines: 0,
        information_raw: {}
    },
    mutations: {
        machineInformation_set(state, data) {
            state.machineInformation[data[0]] = data[1]
        },
        machineInformation_change(state, data) {
            state.machineInformation[data[0]][data[1]].value = data[2]
        },
        machine_setInformation(state, data) {
            state.calculation.machine[data[0]] = data[1]
        },
        changeMachineValue(state, [rootState, process, key, value]) {
            if (!rootState.calculation.processes[process].machine) Vue.set(rootState.calculation.processes[process], "machine", {})
            Vue.set(rootState.calculation.processes[process].machine, key, value)
        },

        machine_changeSelected(state, material) {
            state.catalogue_selected = material
        },
        // machineInformation_change(state, data) {
        //     state.machineInformation[data[0]][data[1]] = data[2]
        // },
        // machine_setInformation(state, [rootState, key, value]) {
        //     Vue.set(rootState.calculation[rootState.process_selected].machine, key, value)
        // },
    },
    actions: {
    },
    getters: {
        getMachineInformation: (state, getters) => (machine) => {
            if (state.machineInformation[machine]) return state.machineInformation[machine]
            const formData = new FormData();
            formData.append('method', "machine_getInformation");
            formData.append('machine_name', machine);
            fetch(getters.path_data, {
                method: "POST",
                body: formData
            }).then(result => {
                return result.json()
            }).then(result => {
                Vue.set(state.machineInformation, machine, result)
                Vue.set(state.information_raw, machine, JSON.stringify(result))
            })
            return 0
        },
        machines: (state, getters) => {
            if (state.machines) return state.machines
            const formData = new FormData();
            formData.append('method', "machine_getNames");
            fetch(getters.path_data, {
                method: "POST",
                body: formData
            }).then(result => {
                return result.json()
            }).then(result => {
                Vue.set(state, "machines", result)
            })
            return 0
        },
        machines_getCatalogueSelected: (state) => state.catalogue_selected,
        machine_isCustomized: (state) => (machine) => JSON.stringify(state.machineInformation[machine]) != state.information_raw[machine],
    }
}

export default machines