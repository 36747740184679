
var toolActions = {
    evaluateTools(rootState, tools) {
        let toolData = {}
        for (const t in tools) {
            toolData[t] = {
                src: toolActions.getToolImage(t),
                time: 0,
                used: 0
            }
            if (!tools[t].length) continue
            
            toolData[t].used = 1
            tools[t].forEach(toolProcess => {
                toolData[t].time = toolProcess.time
            });

        }
        toolActions.changeToolsInCaluclation(rootState, toolData)
    },
    getToolImage(tool) {
        let images = {
            roughing: "https://primecalc.de/assets/icons/tools/C.png",
            grooving: "https://primecalc.de/assets/icons/tools/N.png",
            drilling: "https://primecalc.de/assets/icons/tools/bohrer.png",
            insideturning: "https://primecalc.de/assets/icons/tools/V.png",
            threading: "https://primecalc.de/assets/icons/tools/M.png",
            milling: "https://primecalc.de/assets/icons/tools/endmill.png"
        }
        return images[tool] || "https://kalkulation.primecalc.de/assets/images/icons/tools.svg"
    },
    changeToolsInCaluclation(rootState, toolData) {
        for (const t in toolData) {
            for (const key in toolData[t]) {
                if (!rootState.calculation.tools[t]) Vue.set(rootState.calculation.tools, t, {})
                Vue.set(rootState.calculation.tools[t], key, toolData[t][key])
            }
        }
    }
}

import Vue from "vue";
const processes = {
    state: {
        tools: {
            roughing: [],
            finishing: [],
            grooving: [],
            threading: [],
            drilling: [],
            insideturning: [],
            milling: []
        }
    },
    mutations: {
        tools_evaluate(state, [rootState, tools]) {
            Vue.set(state.tools, "tools", {
                roughing: [],
                finishing: [],
                grooving: [],
                threading: [],
                drilling: [],
                insideturning: [],
                milling: []
            })

            let tool;
            let lotsize = rootState.calculation.information.lotsize
            if (!rootState.calculation.other.rejected) rootState.calculation.other.rejected = 0
            lotsize *= 1 + rootState.calculation.other.rejected / 100 
            for (const key in tools) {
                switch (key) {
                    case "roughing":
                            tool = {
                                time: tools[key].time/60*lotsize
                            }
                            state.tools.roughing.push(tool)
                            // Vue.set(state.toolData, "roughing", tool)
                        break;
                    case "partoff":
                            tool = {
                                time: tools[key].time / 60 * lotsize
                            }
                            state.tools.grooving.push(tool)
                            // Vue.set(state.toolData, "partoff", tool)
                        break;
                    case "grooving":
                            tool = {
                                time: tools[key].time / 60 * lotsize
                            }
                            state.tools.grooving.push(tool)
                            // Vue.set(state.toolData, "grooving", tool)
                        break;
                    case "drilling":
                            tool = {
                                time: tools[key].time / 60 * lotsize
                            }
                            state.tools.drilling.push(tool)
                            // Vue.set(state.toolData, "drilling", tool)
                        break;
                    case "insideturning":
                            tool = {
                                time: tools[key].time / 60 * lotsize
                            }
                            state.tools.insideturning.push(tool)
                            // Vue.set(state.toolData, "insideturning", tool)
                        break;
                    case "threading":
                            tool = {
                                time: tools[key].time / 60 * lotsize
                            }
                            state.tools.threading.push(tool)
                            // Vue.set(state.toolData, "threading", tool)
                        break;
                    default:
                        break;
                }
            }
            toolActions.evaluateTools(rootState, state.tools);
        },
        setToolValue(state, [rootstate, tool, key, value]) {
            if (!rootstate.calculation.tools[tool]) Vue.set(rootstate.calculation.tools, tool, {})
            Vue.set(rootstate.calculation.tools[tool], key, value)
        }
    },
    actions: {
    },
    getters: {
        tools: (state, getters, rootState) => rootState.calculation.tools
    }
}

export default processes

