<template>
    <input v-if="type == 'number'" type="number" :value="value" @input="changeValue($event.target.value)">
    <input v-else type="text" :value="value" @input="changeValue($event.target.value)">
    <!-- <div>

    </div> -->
</template>

<script>
export default {
    name: "pc-input",
    props: ["type", "value"],
    methods: {
        changeValue(value) {
            console.log(value);
            this.$emit("changeValue", value)
        }
    }
}
</script>

<style>

</style>