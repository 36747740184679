<template>
    <div id="box">
        <div id="subProcessOptions" class="card" v-if="subProcess_selected">
            <h3 class="card_title">Nötige Prozessparameter</h3>
            <div class="padding" id="gridContainer">
                <div class="padding">
                    <img id="threadImage" src="https://development.primecalc.de/assets/images/thread.png" alt="">
                </div>
                <div>
                    <p><b>Gewinde</b></p>
                    <div class="flex padding_small radius" v-for="(t, i) in values.thread" :key="i">
                        <p>Länge:</p>
                        <input type="number" min="1" :value="t.length" @input="changeThreadProperty(i, 'length', $event.target.value)">
                        <p>mm</p>
                        <p v-if="diameterNeeded(t.standard)" class="diameter">∅</p>
                        <input v-if="diameterNeeded(t.standard)" type="number" min="1" :value="t.diameter" @input="changeThreadProperty(i, 'diameter', $event.target.value)">
                        <p v-if="diameterNeeded(t.standard)">mm</p>
                        <p>Norm:</p>
                        <select name="" id="" :value="t.standard" @input="changeThreadProperty(i, 'standard', $event.target.value)">
                            <option :value="std" v-for="(std, s) in standards" :key="s">{{std}}</option>
                        </select>
                        <img class="pointer" @click="removeThread(i)" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" alt="">
                    </div>
                    <div id="threadAdd" class="dark padding_small pointer radius flex fitContent" @click="addThread()">
                        <img style="height: 1.5em; width: 1.5em;" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                        <p class="marginAuto">Gewinde hinzufügen</p>
                    </div>
                </div>
                <p v-if="verify" id="next" @click="$store.dispatch('subProcesses_next')" class="blue padding_small radius fitContent pointer" style="margin: .5em 0 0 auto;">weiter</p>
            </div>
        </div>

        <div id="manufacturingtime" class="card" v-if="verify">
            <h3 class="card_title">Prozess genauer definieren</h3>
            <div class="padding">
                <p><b>Schnitte pro Gewinde</b></p>
                <input type="number" :value="values.cuts" @input="changeValue('cuts', $event.target.value)">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            threads: [],
            standards: ["-","M2","M2,5","M3","M4","M5","M6","M8","M10","M12","M14","M16","M18","M20","M22","M24"],
            standardsWithDiameters: ["M2","M2,5","M3","M4","M5","M6","M8","M10","M12","M14","M16","M18","M20","M22","M24"]
        }
    },
    methods: {
        changeValue(key, value) {
            this.$store.commit("changeSubprocessValue", ["thread", key, value]);
            this.$store.dispatch("refresh")
        },  
        addThread() {
            this.$store.commit("pushSubprocessValue", ["thread", "thread", {length: 12, diameter: 10, standard: "-"}])
            this.$store.dispatch("refresh")
        },
        removeThread(i) {
            this.$store.commit("deleteSubprocessList", ["thread", "thread", i])
            this.$store.dispatch("refresh")
        },
        changeThreadProperty(tIndex, key, value) {
            this.$store.commit("changeSubprocessListValue", ["thread", "thread", tIndex, key, value])
            this.$store.dispatch("refresh")
        },
        diameterNeeded(std) {
            if(this.standardsWithDiameters.indexOf(std) == -1) return 1
            else return 0
        },
    },
    computed: {
        values() {
            let values = this.$store.getters.values("value")
            if (!values.cuts) this.changeValue('cuts', 8)
            return this.$store.getters.values("value")
        },
        subProcess_selected() {
            return this.$store.state.subProcess_selected
        },
        verify() {
            return this.$store.getters.verify.processes[this.$store.state.process_selected].subProcesses[this.$store.state.subProcess_selected]
        },
    }
}
</script>

<style scoped>
    #box>.card:first-child{margin-bottom: 2em;}
    #box>.card{width: fit-content;}
    .flex{margin: 4px 0;}
    .flex>input{width: 4em;}
    .flex>p:nth-child(3n){margin-right: 1em;}
    .flex:nth-child(even){background-color: rgb(247, 247, 247);}
    #gridContainer{
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 2em;
    }
    #threadImage{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 12em;
        width: 17em;
        margin: 1em auto;
    }
    #next{
        position: absolute;
        right: .5em;
        bottom: .5em;
    }
    .diameter{
        transform-origin: center;
        transform: scale(1.5);
        margin-right: 5px;
    }
    select{background-color: rgba(0, 0, 0, 0);}
</style>