<template>
    <div class="searchcontainer">
        <input type="text" class="padding_small" :placeholder="placeholder" v-model="searchterm" @focus="isfocused = true" @blur="blur()">
        <div id="entryContainer" class="radius dark" v-if="isfocused">
            <div 
                class="searchEntry padding_small radius_small" 
                @click="select(val)"
                v-for="(val, i) in filtered" :key="i"> 
                {{dict(val)}}
            </div>
        </div>
        <!-- <input 
            class="padding_small"
            v-model="searchterm"
            type="text"
            :placeholder="dict(selected)"> -->
    <!-- <selectlist
        v-show="isfocused"
        class="searchbox"
        :values="values.filter(val => val.match(searchterm))"
        :firstsel="firstsel"
        @selected="($event)=>{setSelected($event);isfocused = false}"></selectlist> -->
    </div>
</template>

<script>
export default {
    name: "Searchlist",
    props: ["values", "displayvalues", "firstsel", "start", "placeholder"],
    data: function () {
        return {
            searchterm: "",
            isfocused: this.start ? true : false,
            selected: this.firstsel,
            mouseover: true,
            filtered: this.displayvalues || this.values,
        }
    },
    mounted() {
        this.$emit('filtered', this.filtered)
    },
    methods: {
        dict(val) {
            return this.$store.state.dict[val] || val
        },
        setSelected(sel) {
            this.selected = sel
            this.$emit('selected', sel)
        },
        blur() {
            setTimeout(() => {
                this.isfocused = false 
            }, 250);
        },
        select(val) {
            // this.placeholder = val
            this.searchterm = ""
            this.$emit("select", val)
        },
    },
    watch: {
        searchterm() {
            let newValues = []
            if (typeof this.values[0] != "object") {
                this.values.forEach((val, i) => {
                    if(val.toLowerCase().match(this.searchterm.toLowerCase())) newValues.push(this.displayvalues[i])
                });
            }
            else {
                this.values.forEach(valObj => {
                    for (const key in valObj) {
                        if (String(valObj[key]).toLowerCase().match(this.searchterm.toLowerCase())) {
                            newValues.push(valObj)
                            return
                        }
                    }
                });
            }
            this.$emit("searched", this.searchterm)
            this.filtered = newValues
        }
    },
    computed: {
        // selectedValue: {
        // get() {
        //     return this.searchterm
        // },
        // set(value) {
        //     this.searchterm = value
        //     this.isfocused = false
        // }
    }
}
</script>

<style scoped>
    #entryContainer{
        max-height: 50vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .searchEntry{
        cursor: pointer;
        transition: .25s;
    }
    .searchEntry:hover{
        background-color: rgb(209, 224, 245);
    }
</style>