const awsmobile = {
    Auth: {
        region: "eu-central-1",
        userPoolId: "eu-central-1_7vcmLz29L",
        userPoolWebClientId: "303jpp2vivtmk36gtk6m5nufrq"
    }, 
    API: {
        endpoints: [
            {
                name: "primecalc_clientAccess",
                endpoint: "https://ut377oqtyb.execute-api.eu-central-1.amazonaws.com"
            }
        ]
    }
};

export default awsmobile;