<template>
    <div>
        <div class="card" style="margin-bottom: 2em; z-index: 1000">
            <h3 class="card_title">Materialdatenbank</h3>
            <div class="padding">
                <div class="box">
                    <p><b>Suche:</b></p>
                    <p v-if="!materials">Lade Materialien</p>
                    <searchlist v-else :values="materials" :placeholder="catalogue_selected" :firstsel="0" @select="changeSelected($event)"></searchlist>
                    <p v-if="catalogue_selected && !materialInformation">Lade Material...</p>
                </div>
                
                <div class="box" v-if="catalogue_selected && materialInformation">
                    <div class="selectGrid">
                        <p>Name:</p>
                        <input type="text" :value="materialInformation.name" @input="changeSelectedProperty('name', $event.target.value)">
                        <p>Code:</p>
                        <input type="text" :value="materialInformation.code" @input="changeSelectedProperty('name', $event.target.code)">
                        <p>Notiz:</p>
                        <textarea type="text" :value="materialInformation.comment" @input="changeSelectedProperty('name', $event.target.comment)"></textarea>
                    </div>
                    <!-- <div id="propertyGrid" v-for="(mat, i) in materialInformation" :key="i" class="flex indent">
                        <p style="flex: 1" v-if="i != 'name'"><b>{{dict(i)}}</b></p>
                        <input type="text" v-if="i != 'name'" :value="mat" @input="customizeMaterial(i, $event.target.value)">
                    </div>
                    <br>
                    <div class="flex">
                        <div v-if="catalogue_selected" id="material_useData" @click="applyPreview()" class="indent padding_small pointer radius blue flex fitContent">
                            <p style="margin: auto .25em">Daten übernehmen</p>
                        </div>                    
                        <div v-if="isCustomized" id="material_useData" @click="saveCustomized()" class="indent padding_small pointer radius blue flex fitContent">
                            <p style="margin: auto .25em">Anpassung speichern</p>
                        </div>
                    </div> -->
                </div>
                
                <!-- <form action="https://xfkq9bi2pj.execute-api.eu-central-1.amazonaws.com/prod" method="post" enctype="multipart/form-data">
                    Key to upload: 
                    <input type="input"  name="auth" value="EOGMEIUEU(GRNGUHRG/ZR" /><br />
                    <input type="hidden" name="method" value="save_get" />
                    <input type="hidden" name="save" value="PrimeCalc_1606080311" />
                    <input type="submit" name="submit" value="Upload" />
                </form> -->

            </div>            
        </div>
        <!-- <div class="card">
            <h3 class="card_title dark">Neues Material hizufügen</h3>
            <div class="padding">
                <img v-if="!newMat" @click="newMat = true" class="dark padding_small radius pointer" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                <div v-else>
                    <div id="newGrid" v-for="(attr, a) in material_new" :key="a">
                        <p>{{dict(a)}}</p>
                        <pc-input :value="attr" @changeValue="material_new[a] = $event"></pc-input>
                    </div>
                    <div class="flex">
                        <searchlist :values="materialAttributeNames" :placeholder="property_selected" :firstsel="0" @select="property_selected = $event" @searched="property_selected = $event"></searchlist>
                        <img :class="{muted: !property_selected}" class="pointer" @click="addNewMaterialAttribute(property_selected)" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                    </div>
                </div>

            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            newMat: false,
            property_selected: false,
            catalogue_selected: "",
            inputTypes: {price_m: "number"},
            material_new: {"hsi": 1}
        }
    },
    computed: {
        
        machinabilityText() {
            let text = ["sehr schlecht", "schlecht", "mittel", "gut", "sehr gut"]
            return text[this.material.machinability]
        },
        materialInformation() {
            return this.$store.getters.getMaterialInformation(this.catalogue_selected)
        },
        isCustomized() {
            if(!this.catalogue_selected) return false
            return this.$store.getters.material_isCustomized(this.catalogue_selected)
        },
        materials() {
            return this.$store.getters.materials
        },
        materialAttributes() {
            return this.$store.getters.materialAttributes
        },
        materialAttributeNames() {
            let names = []
            if(!this.materialAttributes) return []
            for (const name in this.materialAttributes) {
                names.push(this.dict(name))
            }
            return names
        }
    },
    methods: {
        dict(c) {
            return this.$store.state.dict[c] || c
        },
        dict_reverse(c) {
            for (const key in this.$store.state.dict) {
                if(this.$store.state.dict[key] == c) return key
            }
            return c
        },
        changeSelected(material) {
            this.catalogue_selected = material
        },
        applyPreview() {
            for (const key in this.materialInformation) {
                this.$store.commit("material_setInformation", [this.$store.state, key, this.materialInformation[key]])
            }
            this.$store.dispatch("refresh")
        },
        addNewMaterialAttribute(attribute) {
            console.log(this.dict_reverse(attribute));
            this.property_selected = false
            this.material_new[this.dict_reverse(attribute)] = "1"
        }
    }
}
</script>

<style scoped>
    .selectGrid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 4px 1em;
        margin-bottom: 4px;
    }
</style>