<template>    
    <div id="box">
        <div id="subProcessOptions" class="card" v-if="subProcess_selected">
            <h3 class="card_title">Nötige Prozessparameter</h3>
            <div class="padding" id="dimensioning">
                <div id="gridContainer">
                    <div class="card pointer padding">
                        <img src="https://development.primecalc.de/assets/images/radialhole_0.png" alt="">
                        <counter :min="0" :step="1" :initial="values.radialHole_simple" @change="setValue('radialHole_simple', $event)"></counter>
                        <p>Einfache Radiale Bohrung</p>
                    </div>
                    <div class="card pointer padding">
                        <img src="https://development.primecalc.de/assets/images/radialhole_1.png" alt="">
                        <counter :min="0" :step="1" :initial="values.radialHole_complex" @change="setValue('radialHole_complex', $event)"></counter>
                        <p>Radiale Bohrung mit Fräsprozess</p>
                    </div>
                </div>
                <p v-if="verify" @click="$store.dispatch('subProcesses_next')" class="blue padding_small radius fitContent pointer" style="margin: .5em 0 0 auto;">weiter</p>
            </div>
        </div>

        <!-- <div id="manufacturingtime" class="card">
            <h3 class="card_title padding_small dark radius">Prozess genauer definieren</h3>
            <div class="padding">
                <component :is="subProcess_selected+'-evaluation'"></component>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    computed: {
        values() {
            return this.$store.getters.values("value")
        },
        subProcess_selected() {
            return this.$store.state.subProcess_selected
        },
        verify() {
            return this.$store.getters.verify.processes[this.$store.state.process_selected].subProcesses[this.$store.state.subProcess_selected]
        },
    },
    methods: {   
        setValue(key, value) {
            value < 0 ? value = 0 : ""
            this.$store.commit("changeSubprocessValue", ["radial-hole", key, value]);            
        }     
    }
}
</script>

<style scoped>
    #box>.card:first-child{margin-bottom: 2em;}
    #box>.card{width: fit-content;}
    .card{
        transition: .25s;
    }
    #gridContainer{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 2em;
    }
    img{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 8em;
        width: 8em;
        margin: 1em auto 0 auto;
    }
    p{text-align: center;}
</style>