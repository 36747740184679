<template>

    <div id="box"> 
        <div id="subProcessOptions" class="card">
            <h3 class="card_title">Nötige Prozessparameter</h3>
            <div class="padding" id="gridContainer">
                <div class="card pointer padding" :class="{blue: selected === 0}" @click="setContour(0)">
                    <img src="https://development.primecalc.de/assets/images/volumeturning_0.png" alt="">
                    <p>Wenig Bearbeitung</p>
                </div>
                <div class="card pointer padding" :class="{blue: selected == 1}" @click="setContour(1)">
                    <img src="https://development.primecalc.de/assets/images/volumeturning_1.png" alt="">
                    <p>Mittlere Bearbeitung</p>
                </div>
                <div class="card pointer padding" :class="{blue: selected == 2}" @click="setContour(2)">
                    <img src="https://development.primecalc.de/assets/images/volumeturning_2.png" alt="">
                    <p>Viel Bearbeitung</p>
                </div>
                <p></p>
                <p></p>
                <p v-if="verify" @click="$store.dispatch('subProcesses_next')" class="blue padding_small radius fitContent pointer" style="margin: .5em 0 0 auto;">weiter</p>
            </div>
        </div>

        <div class="card" v-if="verify">
            <h3 class="card_title">Prozess genauer definieren</h3>
            <div class="padding">
                <div class="box">
                    <p class="textspacing"><b>Schnittparameter:</b></p>
                    <div id="cuttingParameters" class="indent textspacing">
                        <div>
                            <p>Schnitttiefe ap:</p>
                            <input type="number" :value="values.ap" min="0" step=".1" @input="changeValue('ap', $event.target.value)">
                            <p>mm</p>
                        </div>
                        <div>
                            <p>Vorschub f:</p>
                            <input type="number" :value="values.f" min="0" step="0.05" @input="changeValue('f', $event.target.value)">
                            <p>mm/U</p>
                        </div>
                        <div>
                            <p>Schnittgeschwindigkeit vc:</p>
                            <input type="number" :value="values.vc" min="0" step="1" @input="changeValue('vc', $event.target.value)">
                            <p>m/min</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <p style="display: flex">
                        <b style="flex: 0">Schlichten:</b> 
                        <input type="checkbox" style="margin: auto auto auto 6px" :value="values.finishing_use" @input="changeValue('finishing_use', $event.target.checked)">
                    </p>
                    <div v-if="values.finishing_use" id="cuttingParameters" class="indent">
                        <div>
                            <p>Anzahl Schlichtgänge:</p>
                            <input type="number" :value="values.finishing_count" min="1" step="1" @input="changeValue('finishing_count', $event.target.value)">
                        </div>
                        <div>
                            <p>Vorschub f:</p>
                            <input type="number" :value="values.finishing_f" min="0" step="0.05" @input="changeValue('finishing_f', $event.target.value)">
                            <p>mm/U</p>
                        </div>
                        <div>
                            <p>Schnittgeschwindigkeit vc:</p>
                            <input type="number" :value="values.finishing_vc" min="0" step="1" @input="changeValue('finishing_vc', $event.target.value)">
                            <p>m/min</p>
                        </div>
                        <div>
                            <p>Theoretische Rautiefe Rz:</p>
                            <input type="number" :value="values.finishing_rz" min="0" step="1" @input="changeValue('finishing_rz', $event.target.value)">
                            <p>μm</p>
                        </div>
                    </div>
                </div>
                <!-- <p class="textspacing"><b>Schlichten:</b></p>
                <div id="finishing" class="flex indent textspacing">
                    <div class="flex" id="finishFlex">
                        <p :class="{blue: values.roughness_enabled}" @click="changeValue('roughness_enabled', 1)" class="padding_small pointer radius dark">Ja</p>
                        <p :class="{blue: values.roughness_enabled == false}" @click="changeValue('roughness_enabled', 0)" class="padding_small pointer radius dark">Nein</p>
                    </div>
                    <input min="0.1" step=".1" :class="{'muted': values.roughness_enabled == false}" type="number" :value="values.roughness" @input="changeValue('roughness', $event.target.value)">
                    <p>μm</p>
                </div>
                <p class="textspacing"><b>Benötigte Werkzeuge:</b></p>
                <div id="tools" class="flex indent textspacing">
                    <p>Anzahl:</p>
                    <input min="1" type="number" :value="values.toolcount" @input="changeValue('toolcount', $event.target.value)">
                </div> -->
            </div>
         </div>
     </div>

</template>

<script>
export default {
    data() {
        return {
            roughness_types: ["Ra", "Rz"]
        }
    },
    methods: {    
        changeValue(key, value) {
            switch (key) {
                case "ap":
                        value <= 0.02 ? value = .1 : ""
                    break;
                case "f":
                        value <= 0.001 ? value = .005 : ""
                    break;
                case "vc":
                        value <= 0 ? value = 1 : ""
                    break;
                case "roughness":
                        value < 0.01 ? value = .01 : ""
                    break;
                case "finishing_f":
                        var radius = 0.4
                        var rz = Math.round(value**2/(8*radius)*1000)
                        this.$store.commit("changeSubprocessValue", ["contour", "finishing_rz", rz]);
                    break;
                case "finishing_rz":
                        radius = 0.4
                        var f = Math.round((value/1000*8*radius)**0.5*100)/100
                        this.$store.commit("changeSubprocessValue", ["contour", "finishing_f", f]);
                    break;
                default:
                    break;
            }
            this.$store.commit("changeSubprocessValue", ["contour", key, value]);
            this.$store.dispatch("refresh")
        },  
        setContour(value) {
            if (value === this.selected) this.$store.dispatch('subProcesses_next')
            this.$store.commit("changeSubprocessValue", ["contour", "selected", value]);
            this.$store.dispatch("refresh")
        },
    },
    computed: {
        values() {
            let values = this.$store.getters.values("value")
            if (!values.ap) this.changeValue("ap", 2)
            if (!values.f) this.changeValue("f", .2)
            if (!values.vc) this.changeValue("vc", 150)
            if (!values.roughness) this.changeValue("roughness", .8)
            if (!values.toolcount) this.changeValue("toolcount", 1)
            if (values.roughness_enabled === "") this.changeValue("roughness_enabled", 0)
            return values
        },
        selected() {
            return this.$store.state.calculation.processes[this.$store.state.process_selected].subProcesses["contour"].values.selected.value
        },
        subProcess_selected() {
            return this.$store.state.subProcess_selected
        },
        verify() {
            return this.$store.getters.verify.processes[this.$store.state.process_selected].subProcesses[this.$store.state.subProcess_selected]
        },
        times () {
            let time = 0
            this.$store.getters.times.forEach(t => {
                if(t.type == "roughing") time = t.time
            });
            return time
        }
    }
}
</script>

<style scoped>
    #box>.card:first-child{margin-bottom: 2em;}
    #box>.card{width: fit-content;}
    #gridContainer{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 2em;
    }
    img{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 8em;
        width: 12em;
        margin: 1em auto;
    }
    /* p{text-align: center;} */

    #cuttingParameters>div{
        display: grid;
        grid-template-columns: auto 4em 4em;
        grid-column-gap: 1em;
        margin-bottom: 4px;
    }
    #finishFlex>:nth-child(1) {border-top-right-radius: 0; border-bottom-right-radius: 0;}
    #finishFlex>:nth-child(2) {border-top-left-radius: 0; border-bottom-left-radius: 0;}
    #finishing>input{
        width: 4em;
        height: 1.5em;
        margin: .25em .5em;
    }
    #finishing>p{
        margin: auto 0 auto 0;
    }

    #tools>input{
        width: 4em;
        margin-left: .5em;
    }

</style>