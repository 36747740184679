<template>
    <div>
        <p><b>PDF einlesen</b></p>
        <img class="dark padding_small radius" src="https://primecalc.de/assets/icons/pdf.svg" alt="PDF">
        <img class="dark padding_small radius spacingleft" src="" alt="no PDF">
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>