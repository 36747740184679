import Vue from "vue";
import Vuex from "vuex";
// import p from "@/assets/js/processes.js";
import processes from "@/store/modules/processes.js";
import calculation from "@/store/modules/calculation.js";
import tools from "@/store/modules/tools.js";
import machines from "@/store/modules/machines.js";
import material from "@/store/modules/material.js";
import setup from "@/store/modules/setup.js";
import exp from "@/store/modules/export.js";
import path from "@/store/modules/variables.js";

import apiCall from '@/apiCall'
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loggedIn: false,
        loadedCalculation: false,
        saves: 0,
        save_data :"",
        dict: {},
        processes_verified: 0, 
        process_selected: 0,
        subProcess_selected: 0,
        calculation: {
            name: "Testkalkulation",
            information: {
                name: "Testbauteil",
                customer: "Mein Maschinenbau",
                lotsize: 500,
                lotsizes: [],
                duedate: "2020-11-20",
                ownData: []
            },
            material: {
                name: "TestMaterial",
            },
            machines: [
                
            ],
            tools: {

            },
            processes: [],
            setup: {},
            other: {}
        },
        price: 0,
        time: 0,
        error: "",
        machine_selected: "",
        machines: {},
        machineInformation: {},
        processes_available: ["Lade Daten..."]
    },
    mutations: {
        setLoggedIn(state, data) {
            Vue.set(state, data[0], data[1])
        },
        setCalculationName(state, name) {
            Vue.set(state.calculation, "name", name)
        },
        setValue(state, data) {
            Vue.set(state, data[0], data[1])
            this.dispatch("calculate")
        },
        information_set(state, data) {
            Vue.set(state.calculation.information, data[0], data[1])
        },
        addNewProcess(state, data) {
            // state.calculation.processes.push({
            //     type: data[0],
            //     chain: data[1],
            // })
            if(!data.machine) data.machine = {}
            state.calculation.processes.push(data)
            state.process_selected = state.calculation.processes.length-1
            state.subProcess_selected = state.calculation.processes[state.process_selected].processChain[0]
        },
        deleteProcess(state, index) {
            state.process_selected = 0
            // if (state.process_selected < state.calculation.processes.length - 2) {
            //     state.process_selected = state.calculation.processes.length-1
            // }
            state.calculation.processes.splice(index, 1)
        },
        process_select(state, index) {
            if (state.calculation.processes[index]) {
                state.process_selected = index
            }
            if (state.calculation.processes[state.process_selected]) state.subProcess_selected = state.calculation.processes[state.process_selected].processChain[0]
        },
        process_setMachine(state, data) {
            state.calculation.processes[data[0]].machine = data[1]
        },
        loadCalculation(state, data) {
            for (const key in data) {
                state.calculation[key] = data[key]
            }
            state.loadedCalculation = document.cookie.match(/calculation_loaded=\d+/)[0].replace("calculation_loaded=", "")
        },
        lotsize_add(state, lotsize){
            if (!state.calculation.information.lotsizes) state.calculation.information.lotsizes = []
            Vue.set(state.calculation.information.lotsizes, state.calculation.information.lotsizes.length, lotsize)
        },
        lotsize_change(state, [i, lotsize]) {
            if (!lotsize) {
                state.calculation.information.lotsizes.splice(i, 1)
                return
            }
            Vue.set(state.calculation.information.lotsizes, i, lotsize)
        },
        addSubprocessValue(state, data) {
            Vue.set(state.calculation.processes[state.process_selected].subProcesses[data[0]].values, data[1], {
                value: "",
                required: false
            }) 
        }, 
        pushSubprocessValue(state, data) {
            state.calculation.processes[state.process_selected].subProcesses[data[0]].values[data[1]].value.push(data[2])
        },
        changeSubprocessValue(state, data) {
            if (typeof data[2] == "number" && isNaN(data[2])) data[2] = ""
            if (!state.calculation.processes[state.process_selected].subProcesses[data[0]]) {
                Vue.set(state.calculation.processes[state.process_selected].subProcesses[data[0]], 'values', {})
            }
            if (!state.calculation.processes[state.process_selected].subProcesses[data[0]].values[data[1]]) {
                Vue.set(state.calculation.processes[state.process_selected].subProcesses[data[0]].values, data[1], {})
            }
            if (!state.calculation.processes[state.process_selected].subProcesses[data[0]].values[data[1]].value) {
                Vue.set(state.calculation.processes[state.process_selected].subProcesses[data[0]].values[data[1]], 'value', "")
            }
            Vue.set(state.calculation.processes[state.process_selected].subProcesses[data[0]].values[data[1]], "value", data[2])
        },
        changeSubprocessListValue(state, data) {
            let [sP, value, index, key, val] = data
            if (typeof data[2] == "number" && isNaN(data[2])) data[2] = ""
            let subProcesses = state.calculation.processes[state.process_selected].subProcesses
            if (!subProcesses[sP]) {
                Vue.set(subProcesses[sP], 'values', {})
            }
            if (!subProcesses[sP].values[value]) {
                Vue.set(subProcesses[sP].values, value, {})
            }
            if (!subProcesses[sP].values[value].value) {
                Vue.set(subProcesses[sP].values, "value", [])
            }
            if (!subProcesses[sP].values[value].value[index]) {
                Vue.set(subProcesses[sP].values[value].value, index, {})
            } 
            Vue.set(subProcesses[sP].values[value].value[index], key, val)
        },
        deleteSubprocessList(state, [subProcess, key, index]) {
            state.calculation.processes[state.process_selected].subProcesses[subProcess].values[key].value.splice(index, 1)
        },
        setSetupValue(state, [key, value]) {
            Vue.set(state.calculation.setup, key, value)
        },
        setOtherValue(state, [key, value]) {
            Vue.set(state.calculation.other, key, value)
        },
        logout(state, router) {
            const formData = new FormData();
            formData.append('method', "logout");
            fetch(this.getters.path_account, {
                method: "POST",
                body: formData
            }).then(() => {
                router.push("/")
                state.loadedCalculation = false
                // document.location.reload()
            })
        },
        saves_delete(state, id) {
            apiCall({
                method: "save_delete",
                save: id
            }).then(response => {
                if (response.statusCode == 200) {
                    Vue.delete(state.saves, id)
                }
            })
        },
    },
    actions: {
        subProcesses_next() {
            const checkNestedCondition = (subprocess, nested) => {
                let parent = this.state.calculation.processes[this.state.process_selected].subProcesses[subprocess]
                nested = this.state.calculation.processes[this.state.process_selected].subProcesses[nested]
                console.log(parent, nested);
                let conditionsMet = true
                for (const cond in nested.condition) {
                    if (parent.values[cond].value != nested.condition[cond]) {
                        conditionsMet = false
                    }
                }
                return conditionsMet
            }

            let chain = this.state.calculation.processes[this.state.process_selected].processChain
            let subProcess = this.state.calculation.processes[this.state.process_selected].subProcesses[this.state.subProcess_selected]
            let i = chain.indexOf(this.state.subProcess_selected)
            if (i == -1) i = chain.indexOf(subProcess.parent)
            //  If ist nested process
            if (subProcess.parent) {
                let parent = this.state.calculation.processes[this.state.process_selected].subProcesses[subProcess.parent]
                let iOfNested = parent.nestedProcesses.indexOf(this.state.subProcess_selected)
                iOfNested++;
                if (iOfNested >= parent.nestedProcesses.length) {
                    if (i < this.state.subProcess_selected.length) i++
                    console.log(i);
                    this.state.subProcess_selected = chain[i]
                }
                return
            }
            //  If Nested needed
            if (subProcess.nestedProcesses) {
                let n = 0;
                while (n < subProcess.nestedProcesses.length) {
                    if (checkNestedCondition(this.state.subProcess_selected, subProcess.nestedProcesses[n])) {
                        this.state.subProcess_selected = subProcess.nestedProcesses[n]
                        return
                    }
                    n++
                }
                
            }
            //  Next
            if(i < chain.length) i++
            this.state.subProcess_selected = chain[i]
        },
        generateSave() {
            this.state.save_data = JSON.stringify(this.state.calculation)
        },
        logout() {
            Vue.prototype.$Amplify.Auth.signOut().then(() => {
                document.location.reload()
            }).catch(e => console.log(e));
        },
        save() {
            Vue.set(this.state, "save_data", JSON.stringify(this.state.calculation))            

            apiCall({
                method: "save_save",
                save: document.cookie.match(/calculation_loaded=\d+/)[0].replace("calculation_loaded=", ""),
                data: this.state.save_data
            }).then(response => {
                if (response.statusCode == 200) {
                    console.log("saved");
                }
            })
        },
        unloadCalculation() {
            console.log("unload");
            document.cookie =  'calculation_loaded=; Max-Age=-99999999;';
            this.state.loadedCalculation = 0
            this.$router.push("/")
            setTimeout(() => {
                document.location.reload()
            }, 100);
        },
        saves_getAll() {
            apiCall({
                method: "saves_getAll"
            }).then(response => {
                if (response.statusCode == 200) {
                    this.state.saves_loading = false
                    this.state.saves = response.body
                }
                else {
                    this.state.saves = "none"
                }
            })
        },
        loadCalculation() {
            const calcId = document.cookie.match(/calculation_loaded=\d+/)[0].replace("calculation_loaded=", "")
            if (this.state.loadedCalculation == calcId) return
            apiCall({
                method: "save_get",
                save: calcId
            }).then(response=>{
                if (response.statusCode == 200) {
                    this.commit("loadCalculation", response.body)
                    this.state.save_data = JSON.stringify(this.state.calculation)
                    this.dispatch("refresh")
                    this.commit("tools_evaluate", [this.state, this.getters.tools_raw])
                }
            })
        },
        refresh() {
            this.dispatch("calculate")
            // this.commit("tools_evaluate", [context.rootState, this.getters.tools_raw])
        }
    },
    getters: {
        authenticated: (state) => state.loggedIn,
        information: (state) => state.calculation.information,
        saves: (state) => state.saves,
        values: (state) => (val) => {
            if (!val) val = "value"
            let values = {}
            for (const key in state.calculation.processes[state.process_selected].subProcesses[state.subProcess_selected].values) {
                values[key] = state.calculation.processes[state.process_selected].subProcesses[state.subProcess_selected].values[key][val]
            }
            return values
        },
        lotsizes: (state) => {
            if (!state.calculation.information.lotsizes) state.calculation.information.lotsizes = []
            return state.calculation.information.lotsizes
        }, 
        lotsize_prices: (state) => state.lotsize_prices,
        specificValue: (state) => (p, sP, key) => {
            try {
                return state.calculation.processes[p].subProcesses[sP].values[key]
            } catch (error) {
                return 0                
            }
            
        },
        verify: (state) => {
            if (state.process_selected == -1) return [0]
            let checkCondition = (subprocess, p = state.process_selected) =>{
                let process = state.calculation.processes[p].subProcesses[subprocess]
                let reqMet = 1
                // if(subprocess != "inside-complexity") return 0
                for (var valKey in process.values) {
                    // console.log(valKey);
                    valKey = process.values[valKey]
                    // console.log(valKey);
                    if (!valKey.required || !reqMet) {
                        continue
                    }
                    else if (!valKey.type) {
                        if (valKey.value == "" && valKey.value !== 0 || valKey.value === false) reqMet = 0
                    }
                    // else if (process.nestedProcesses) {
                    //     process.nestedProcesses.forEach(nested => {
                    //         if (!checkCondition(nested)) reqMet = 0
                    //     });
                    // }
                    else {
                        switch (valKey.type) {
                            case "list":
                                if (!valKey.value.length) reqMet = 0
                                break;
                            default:
                                // console.log(reqMet, valKey.value, valKey.value == "undefined");
                                if (valKey.value == "" && valKey.value !== 0 || valKey.value === false || valKey.value === "NaN") reqMet = 0
                                // console.log(reqMet);
                                break;
                        }
                    }
                }
                // console.log("fin: "+reqMet);
                return reqMet
            }
            let checkNestedCondition = (subprocess, nested, p = state.process_selected) => {
                let parent = state.calculation.processes[p].subProcesses[subprocess]
                nested = state.calculation.processes[p].subProcesses[nested]
                let conditionsMet = true
                for (const cond in nested.condition) {
                    if (parent.values[cond].value != nested.condition[cond]) {
                        conditionsMet = false
                    }
                }
                return conditionsMet
            }

            let processStates = []
            let allMet, pMet, spMet
            let sPVerified

            allMet = 1
            state.calculation.processes.forEach((process, p) => {
                sPVerified = {}
                pMet = 1
                process.processChain.forEach(subProcess => {
                    spMet = 1
                    if (checkCondition(subProcess, p) == 0) spMet = 0
                    if (process.subProcesses[subProcess].required && checkCondition(subProcess, p) == 0) {
                        pMet = 0
                    }
                    if (process.subProcesses[subProcess].nestedProcesses) {
                        process.subProcesses[subProcess].nestedProcesses.forEach(nested => {
                            // spMet = 0
                            if (checkNestedCondition(subProcess, nested, p)) {
                                if (checkCondition(nested, p)) sPVerified[nested] = 1
                                else sPVerified[nested] = 0
                                if (process.subProcesses[nested].required && checkCondition(nested, p) == 0) {
                                    spMet = 0
                                }
                            }
                            if (checkCondition(nested, p) == 0) sPVerified[nested] = 0
                        });
                    }
                    sPVerified[subProcess] = spMet
                });
                if (!pMet) allMet = 0
                processStates[p] = { subProcesses: sPVerified, met: pMet}
            });
            return { processes: processStates, all: allMet }
        },
        isSaved: (state) => state.save_data == JSON.stringify(state.calculation)
    },
    modules: {
        p: processes,
        c: calculation,
        mach: machines,
        mat: material,
        setup: setup,
        t: tools,
        exp: exp,
        path: path,
    }
});
