<template>
    <div id="box">
        <div id="subProcessOptions" class="card">
            <h3 class="card_title">Nötige Prozessparameter</h3>
            <div class="padding" id="gridContainer">
                <div class="card pointer padding" :class="{blue: values.selected == 'none'}" @click="setInside('none')">
                    <img src="https://development.primecalc.de/assets/images/solid.png" alt="">
                    <p>Keine Innenbearbeitung</p>
                </div>
                <div class="card pointer padding" :class="{blue: values.selected == 'centering'}" @click="setInside('centering')">
                    <img src="https://development.primecalc.de/assets/images/centering.png" alt="">
                    <p>Nur einfahe Bohrungen</p>
                </div>
                <div class="card pointer padding" :class="{blue: values.selected == 'inside'}" @click="setInside('inside')">
                    <img src="https://development.primecalc.de/assets/images/inside_0.png" alt="">
                    <p>Innenbearbeitung</p>
                </div>
                <p></p><p></p>
                <p v-if="verify" @click="$store.dispatch('subProcesses_next')" class="blue padding_small radius fitContent pointer" style="margin: .5em 0 0 auto;">weiter</p>
            </div>
        </div>

        <!-- <div id="manufacturingtime" class="card">
            <h3 class="card_title padding_small dark radius">Prozess genauer definieren</h3>
            <div class="padding">
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    computed: {
        values() {
            return this.$store.getters.values("value")
        },
        verify() {
            return this.$store.getters.verify.processes[this.$store.state.process_selected].subProcesses[this.$store.state.subProcess_selected]
        }
    },
    methods: {   
        changeValue(key, value) {
            this.$store.commit("changeSubprocessValue", ["inside", key, value]);
            this.$store.dispatch("refresh")
        },  
        setInside(value) {
            this.$store.commit("changeSubprocessValue", ["inside", "selected", value]);
            this.$store.dispatch("subProcesses_next")
            this.$store.dispatch("refresh")
        },
    }
}
</script>

<style scoped>
    #box>.card:first-child{margin-bottom: 2em;}
    #box>.card{width: fit-content;}
    #gridContainer{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 2em;
    }
    #gridContainer>div:nth-child(2)>img{
        width: 18em;
    }
    img{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 12em;
        width: 12em;
        margin: 1em auto;
    }
    p{text-align: center;}
</style>