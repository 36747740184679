<template>
    <div class="counter">
        <input type="number" min=0 v-model="value">
        <div>
            <img @click="value += parseFloat(step)" class="pointer" :min="min" :max="max" :step="step" src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
            <img @click="value -= parseFloat(step)" class="pointer" :min="min" :max="max" :step="step" src="https://dashboard.pixelprime.de/assets/icons/line.svg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: "Counter",
    props: ["initial", "min", "max", "step"],
    data() {
        return {
            value: this.initial
        }
    },
    watch: {
        value() {
            if(this.value < this.min) this.value = this.min
            if(this.value > this.max) this.value = this.max
            this.value = parseFloat(this.value)
            this.$emit("change", this.value)
        },
        initial() {
            this.value = parseFloat(this.initial)
        }
    }
}
</script>

<style scoped>
    .counter{
        margin: 0 auto;
        display: grid;
        grid-template-columns: 2.5em 2em;
        width: fit-content;
    }
    .counter>input{
        height: fit-content;
        margin: auto 0;
    }
    .counter>div{
        display: grid;
        grid-template-rows: 1.5em 1.5em;
    }
    .counter>div>img:first-child{transform: translateY(4px);}
    .counter>div>img{
        opacity: .75;
        width: 1.5em;
        height: 1.5em;margin: 0;
    }
    .counter>div>img:hover{opacity: 1;}
    input{width: 2em}
</style>