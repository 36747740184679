var contour = {
    get(context, state, pState) {
        if (!state) return
        let process = state.calculation.processes[state.process_selected]
        // if (process.subProcesses.dimensions.values.length.value == "" || process.subProcesses.dimensions.values.diameter.value == "") return
        let region = contour.getContourRegion(process)
        if (!region) return [0, "No Region"]
        let parameters = contour.getContourParameters(process)
        let path = contour.getContourPath(region, parameters)
        let time = contour.contour_calculateTime(parameters, path)
        time *= context.getters.getTimeCorrectionFactor("roughing")

        pState.tools.roughing = {parameters, time}
        let changeTime = state.calculation.processes[0].machine.toolchange_time || 4
        context.commit("addToManufactureChain", { time, tool: "roughing", type: "roughing" })
        if (process.subProcesses.contour.values.toolcount) {
            for (let i = 0; i < process.subProcesses.contour.values.toolcount.value-1; i++) {
                context.commit("addToManufactureChain", { time: changeTime, type: "toolchange" })
            }
        }
        //  Finishing
        if (!process.subProcesses.contour.values.finishing_use.value) return [time]
        parameters = contour.getFinishingPrameters(process)
        path = contour.getFinishingPath(region, parameters)
        let finishTime = contour.contour_calculateTime(parameters, path) * context.getters.getTimeCorrectionFactor("finishing") 
        pState.tools.finishing = { parameters, finishTime }
        context.commit("addToManufactureChain", { time: finishTime, tool: "finishing", type: "finishing" })
        // if (process.subProcesses.contour.values.toolcount) {
        //     for (let i = 0; i < process.subProcesses.contour.values.toolcount.value - 1; i++) {
        //         context.commit("addToManufactureChain", { time: changeTime, type: "toolchange" })
        //     }
        // }

        return [time + finishTime]
    },
    getContourParameters(process) {
        let parameters = {
            ap: 2,
            f: .2,
            vc: 150
        }
        if (process.subProcesses.contour.values.ap) parameters.ap = process.subProcesses.contour.values.ap.value
        if (process.subProcesses.contour.values.f) parameters.f = process.subProcesses.contour.values.f.value
        if (process.subProcesses.contour.values.vc) parameters.vc = process.subProcesses.contour.values.vc.value
        return parameters
    },
    getFinishingPrameters(process) {
        let parameters = {
            count: 1,
            f: .1,
            vc: 150
        }
        if (process.subProcesses.contour.values.finishing_count) parameters.count = process.subProcesses.contour.values.finishing_count.value
        if (process.subProcesses.contour.values.finishing_f) parameters.f = process.subProcesses.contour.values.finishing_f.value
        if (process.subProcesses.contour.values.finishing_vc) parameters.vc = process.subProcesses.contour.values.finishing_vc.value
        return parameters
    },
    getContourRegion(process) {
        let values = process.subProcesses.contour.values
        let dimensions = process.subProcesses.dimensions.values
        let removalRatio = values.selected.value

        let dRatio = 1
        if (dimensions.raw_diameter && dimensions.raw_diameter.value > dimensions.diameter.value) dRatio = dimensions.raw_diameter.value / dimensions.diameter.value


        let region = 0;
        switch (String(removalRatio)) {
            case "0":
                region = [[0, dRatio], [.1, dRatio], [.1, .9], [.8, .9], [.8, .8], [1, .8], [1, 0]]
                break;
            case "1":
                region = [[0, dRatio], [.1, dRatio], [.1, .75], [.8, .75], [.8, .5], [1, .5], [1, 0]]
                break;
            case "2":
                region = [[0, dRatio], [.1, dRatio], [.1, .5], [.5, .5], [.5, .25], [1, .25], [1, 0]]
                break;
        }
        if (!region) return region
        let [diameter, length] = [process.subProcesses.dimensions.values.diameter.value, process.subProcesses.dimensions.values.length.value]
        if (process.subProcesses.dimensions.values.raw_length.value > length) length = process.subProcesses.dimensions.values.raw_length.value
        region.forEach(point => {
            point[0] *= length
            point[1] *= diameter
        });
        return region
    },
    getContourPath(region, parameters) {
        let path = []
        region = p.pointsArrayToObj(region)
        let domain, int, lastV, connectingPath
        let lastPoint, vertex, onContour
        function getConnectingPath(point, lastPoint, verticies) {
            let startI, endI, connectingVerts, vertex, lastV
            startI = -1;
            endI = -1;
            for (let i = 1; i < verticies.length; i++) {
                vertex = verticies[i]
                lastV = verticies[i - 1]
                if (p.pointOnLine(point, [vertex.z, vertex.x], [lastV.z, lastV.x])) startI = i
                if (p.pointOnLine(lastPoint, [vertex.z, vertex.x], [lastV.z, lastV.x])) endI = i
            }
            if (startI == endI) {
                return [lastPoint]
            }
            if (startI > -1 && endI > -1) {
                connectingVerts = []
                if (endI - startI < verticies.length / 2) {
                    for (let i = startI; i <= endI; i++) {
                        connectingVerts.push([verticies[i].z, verticies[i].x])
                    }
                }
                else {
                    for (let i = endI; i <= startI; i++) {
                        connectingVerts.push([verticies[i].z, verticies[i].x])
                    }
                }
                return connectingVerts
            }
            else return [lastPoint]
        }

        lastPoint = 0
        //  Get Domain
        domain = contour.contour_domain(region)
        let layers = Math.floor((domain[1][1] - domain[1][0]) / parameters.ap + 1)
        //  For Each Layer
        let x
        for (let l = 0; l <= layers; l++) {
            x = domain[1][1] - l * parameters.ap  //  New X Level
            //  Actual Turning
            x < domain[1][0] ? x = domain[1][0] : ""
            !l ? x = domain[1][1] - .001 : ""

            //  Get Intersect Line on Start
            for (let i = 0; i < region.length; i++) {
                vertex = region[i]
                if (!i) continue
                lastV = region[i - 1]
                int = p.lineIntersect([vertex.z, vertex.x], [lastV.z, lastV.x], [domain[0][1], x], [domain[0][1] + 100, x])

                onContour = 0
                region.forEach((vertex, i) => {
                    if (!i) return
                    let lastV = region[i - 1]
                    onContour += p.pointOnLine(int, [vertex.z, vertex.x], [lastV.z, lastV.x])
                });

                if (p.round(int[0], 3) == p.round(domain[0][1], 3)) continue
                if (!onContour) continue
                if (!lastPoint) {
                    lastPoint = int
                    break;
                }
                if (isNaN(int[0]) || isNaN(int[1])) continue

                path.push({ x: x, z: domain[0][1] + .5, type: "rapid" })
                path.push({ x: x, z: domain[0][1] + .5, type: "cut" })
                path.push({ x: x, z: int[0], type: "cut" })
                if (lastPoint[0] != int[0]) {
                    connectingPath = getConnectingPath(lastPoint, [int[0], x], region).reverse()
                    connectingPath.forEach(vertex => {
                        path.push({ x: vertex[1], z: vertex[0], type: "cut" })
                    });
                }
                if (!isNaN(path[path.length - 1].x) && !isNaN(path[path.length - 1].z)) {
                    path.push({ x: path[path.length - 1].x + .25, z: path[path.length - 1].z, type: "cut" })
                    path.push({ x: path[path.length - 1].x, z: domain[0][1] + .5, type: "rapid" })
                }
                lastPoint = int
            }
        }
        return path
        // this.duration = this.time_calculate(this.paths[0])
    },
    getFinishingPath(region, parameters) {
        region = p.pointsArrayToObj(region)
        let path = []
        for (let i = 0; i < parameters.count; i++) {           
            path.push({ z: region[0].z, x: region[0].x + 5, type: "rapid" })
            path.push({ z: region[0].z, x: region[0].x + .5, type: "rapid" })
            path.push({ z: region[0].z, x: region[0].x, type: "cut" })
            region.forEach(v => {
                path.push({ z: v.z, x: v.x, type: "cut" })
            });
        }
        return path
    },
    contour_calculateTime(parameters, path) {
        let time = { cut: 0, rapid: 0 }
        let n, vf
        let [f, vc] = [parameters.f, parameters.vc]
        let n_max = 4000
        let rapid = 400

        if (!path || !path.length) return 0
        path.forEach((v, vi) => {
            if (!vi) return
            vi = path[vi - 1]
            let distance = p.dist([v.x, v.z], [vi.x, vi.z])
            if (distance == undefined || isNaN(distance)) return
            if (v.type == "cut") {
                n = vc * 1000 / (Math.PI * Math.abs(v.x))
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "rapid") {
                time.rapid += distance / rapid
            }
            else if (v.type == "drilling") {
                n = vc * 1000 / (Math.PI * this.parameters.diameter)
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "partRotate") {
                time.rapid += (.5 / 360 * v.angle)
            }
            else if (v.type == "dwell") {
                n = vc * 1000 / (Math.PI * this.parameters.diameter)
                n > n_max ? n = n_max : ""
                time.cut += v.rotations / n * 60
            }
        });
        return time.cut + time.rapid
    },
    contour_domain(contour) {
        let domain = [[Infinity, -Infinity], [Infinity, -Infinity]]
        if (!contour.length) return
        contour.forEach(vertex => {
            vertex.z < domain[0][0] ? domain[0][0] = vertex.z : ""
            vertex.z > domain[0][1] ? domain[0][1] = vertex.z : ""
            vertex.x < domain[1][0] ? domain[1][0] = vertex.x : ""
            vertex.x > domain[1][1] ? domain[1][1] = vertex.x : ""
        });
        return domain
    },
}
var sides = {
    get(context, state, pState) {
        if (!state) return
        let values = state.calculation.processes[state.process_selected].subProcesses.sides.values
        let selected = values.selected.value
        let materialType = state.calculation.material.type
        let time = 0
        let processes = []

        if (!selected || selected === 1) return [0]
        if (materialType == "rod" || materialType == "m") {
            pState.tools.partoff = { time:6 }
            processes.push({ time, tool: "partoff", type: "partoff" })
        }
        if (values.sidechange_manual && values.sidechange_manual.value) time = 45
        else time = state.calculation.processes[0].machine.piecechange_time || 6
        time *= context.getters.getTimeCorrectionFactor("sidechange")
        processes.push({ time, type: "sidechange" })
        
        if (processes.length > 1) {
            context.commit("addToManufactureChain", {parallel: 1, processes})
        }
        else if (processes.length == 1) {
            context.commit("addToManufactureChain", processes[0])
        }
        // if (selected == 2) {
        //     if (values.sidechange_manual && values.sidechange_manual.value) {
        //         time = 45
        //     }
        //     else time = state.calculation.machines.changetime || 4
        // }
        // if (state.calculation.material.type != "piece") {
        // }
        // context.commit("addToManufactureChain", { time , type:"sidechange"})
        return [time, " (Seitenwechsel)"] 
    },
}
var grooves = {
    get(context, state, pState) {
        let time = 0
        let values = state.calculation.processes[state.process_selected].subProcesses.grooves.values
        let diameter = parseFloat(state.calculation.processes[state.process_selected].subProcesses.dimensions.values.diameter.value)
        let length = parseFloat(state.calculation.processes[state.process_selected].subProcesses.dimensions.values.length.value)
        let parameters = grooves.getParameters(values)

        time += grooves.groovesRadial(parseInt(values.grooves_radial.value), parameters, diameter)
        if (length) time += grooves.groovesContour(parseInt(values.grooves_contour.value), parameters, diameter, length)
        time += grooves.groovesAxial(parseInt(values.grooves_axial.value), parameters, diameter)
        time *= context.getters.getTimeCorrectionFactor("grooving")
        pState.tools.grooving = { parameters, time }
        //  Rapid
        time += 2 * (parseInt(values.grooves_radial.value) + parseInt(values.grooves_contour.value) + parseInt(values.grooves_axial.value))
       
        if (time) context.commit("addToManufactureChain", { time, tool: "grooving", type: "grooving" })
        return [parseFloat(time)]
    },
    getParameters(values) {
        let parameters = {
            width: 2,
            f: .1,
            vc: 130
        }
        if (values.width) parameters.width = values.width.value
        if (values.f) parameters.f = values.f.value
        if (values.vc) parameters.vc = values.vc.value
        return parameters
    },

    groovesRadial(grooveCount, parameters, diameter) {
        let path = grooves.groovesRadial_path(diameter)
        let time = grooveCount * grooves.calculateTime(path, parameters)
        return time
    },
    groovesRadial_path(diameter) {
        let depth = 2
        let path = [
            {type: "rapid", z: 0, x: diameter + .5,},
            {type: "cut", z: 0, x: diameter + .5,},
            {type: "cut",   z: 0, x: diameter - 2*depth,},
            {type: "cut",   z: 0, x: diameter + .5,}
        ]
        return path
    },

    groovesAxial(grooveCount, parameters, diameter) {
        let path = grooves.groovesAxial_path(diameter)
        let time = grooveCount * grooves.calculateTime(path, parameters)
        return time
    },
    groovesAxial_path(diameter) {
        let depth = 2
        let path = [
            { type: "rapid", z:  .5, x: diameter*0.6, },
            { type: "cut", z:  .5, x: diameter*0.6, },
            { type: "cut", z: -depth, x: diameter*0.6, },
            { type: "cut", z:  .5, x: diameter*0.6, }
        ]
        return path
    },

    groovesContour(grooveCount, parameters, diameter, length) {
        let path = grooves.grooveContour_path(parameters, diameter, length)
        let time = grooveCount * grooves.calculateTime(path, parameters)
        return time
    },
    grooveContour_path(parameters, diameter, length) {
        let lengthRatio = 0.4
        let depthRatio = 0.2
        let region = [[0, 1], [lengthRatio / 6, 1 - depthRatio], [5 * lengthRatio / 6, 1 - depthRatio], [lengthRatio, 1]]
        region.forEach(point => {
            point[0] *= length
            point[1] *= -diameter
        });

        //////////////// Path
        let path = []
        let zLayers, z0, x0, v1, v2, x1, z
        region = p.pointsArrayToObj(region)
        let domain = contour.contour_domain(region)
        zLayers = Math.round(Math.abs(domain[0][1] - domain[0][0]) / parameters.width)
        z0 = domain[0][0]
        x0 = domain[1][0]
        for (let zL = 0; zL < zLayers; zL++) {
            //  Intersection
            x1 = domain[1][1]
            if (zL == zLayers - 1) z = domain[0][1] - parameters.width
            else z = z0 + zL * parameters.width

            for (let v = 1; v < region.length; v++) {
                [v1, v2] = [region[v], region[v - 1]]
                if (!p.linesCrossing([v1.z, v1.x], [v2.z, v2.x], [z, x0], [z, domain[1][1]])) continue
                let int = p.lineIntersect([v1.z, v1.x], [v2.z, v2.x], [z, x0], [z, domain[1][1]])
                int[1] <= x1 ? x1 = int[1] : ""
                // else int[1] < x1 ? x1 = int[1] : ""
            }
            path.push({
                type: "rapid",
                z: z,
                x: domain[1][0] + .2,
            })
            path.push({
                type: "cut",
                z: z,
                x: x1,
            })
            path.push({
                type: "cut",
                z: z,
                x: domain[1][0] + .2,
            })
        }
        path.push({
            type: "rapid",
            z: region[0].z,
            x: region[0].x,
        })
        region.forEach(v => {
            if (v.z == path[path.length - 1].z) {
                path.push({
                    type: "rapid",
                    z: v.z,
                    x: v.x,
                })
                return
            }
            // if (!iv) {
            //     path.push({
            //         type: "rapid",
            //         z: region[iv].z,
            //         x: region[iv].x,
            //     })
            // } 
            // // if (v.x != domain.x[this.inside ? 0 : 1]) {
            // // }
            if (this.inside)
                path.push({
                    type: (v.x != domain[1][0] ? "cut" : "rapid"),
                    z: v.z,
                    x: v.x,
                })
            else
                path.push({
                    type: (v.x != domain[1][1] ? "cut" : "rapid"),
                    z: v.z,
                    x: v.x,
                })
        });
        return path
    },

    calculateTime(path, parameters) {
        let time = { cut: 0, rapid: 0 }
        let n, vf
        let [f, vc] = [parameters.f, parameters.vc]
        let n_max = 4000
        let rapid = 400

        if (!path || !path.length) return 0
        path.forEach((v, vi) => {
            if (!vi) return
            vi = path[vi - 1]
            let distance = p.dist([v.x, v.z], [vi.x, vi.z])
            if (distance == undefined || isNaN(distance)) return
            if (v.type == "cut") {
                n = vc * 1000 / (Math.PI * Math.abs(v.x))
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "rapid") {
                time.rapid += distance / rapid
            }
            else if (v.type == "drilling") {
                n = vc * 1000 / (Math.PI * this.parameters.diameter)
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "partRotate") {
                time.rapid += (.5 / 360 * v.angle)
            }
            else if (v.type == "dwell") {
                n = vc * 1000 / (Math.PI * this.parameters.diameter)
                n > n_max ? n = n_max : ""
                time.cut += v.rotations / n * 60
            }
        });
        return time.cut + time.rapid
    }
}
var inside = {
    get(context, state, pState) {
        let time = 0
        // let valuesInside = state.calculation.processes[state.process_selected].subProcesses.inside.values
        let insideSelected = state.calculation.processes[state.process_selected].subProcesses.inside.values.selected.value
        let vComplexity = state.calculation.processes[state.process_selected].subProcesses.["inside-complexity"].values
        let vDimensions = state.calculation.processes[state.process_selected].subProcesses.dimensions.values
        if (insideSelected == "none") {
            pState.tools.drilling = { time: 0 }
            pState.tools.insideturning = { time: 0 }            
        }
        else if (vComplexity.selected.value !== "") {
            let parameters = inside.getParameters(vComplexity)
            time += inside.drilling(vComplexity, vDimensions, parameters)
            time *= context.getters.getTimeCorrectionFactor("drilling")
            pState.tools.drilling = { parameters, time }
            context.commit("addToManufactureChain", { time, tool: "drilling", type: "drilling" })
            if (vComplexity.selected.value === "turning" || vComplexity.selected.value === "complex") {
                let insideTime = inside.insideTurning(parameters, vDimensions, vComplexity)
                insideTime *= context.getters.getTimeCorrectionFactor("insideturning")
                time += insideTime
                context.commit("addToManufactureChain", { time: insideTime, tool: "insideturning", type: "insideturning" })
                pState.tools.insideturning = { parameters, time: insideTime }
            }
            else pState.tools.insideturning = { time: 0 }
        }  
        else if (insideSelected == "centering") {
            time = 5
            time *= context.getters.getTimeCorrectionFactor("drilling")
            context.commit("addToManufactureChain", { time, tool: "drilling", type: "drilling" })
            pState.tools.drilling = { time }
            pState.tools.insideturning = { time: 0 }            
        }
        else {
            pState.tools.drilling = { time: 0 }
            pState.tools.insideturning = { time: 0 }
        } 
        // else if (valuesInside.selected.value == "centering") {
            // let parameters = inside.getParameters(valuesComplexity)
            // time += sides.centering(parseInt(values.grooves_radial.value), parameters, diameter)

        // }
        return [parseFloat(time)]
    },
    getParameters(values) {
        let parameters = {
            diameter: 20,
            ap: .5,
            f: .05,
            vc: 120
        }
        if (values.inside_diameter) parameters.diameter = parseFloat(values.inside_diameter.value)
        if (values.ap) parameters.ap = parseFloat(values.ap.value)
        if (values.f) parameters.f = parseFloat(values.f.value)
        if (values.vc) parameters.vc = parseFloat(values.vc.value)
        return parameters
    },

    drilling(vComplexity, vDimensions, parameters) {
        let depth = parseFloat(vComplexity.inside_depth.value/100*vDimensions.length.value)
        //  Path
        let path = [
            { z: .5, x: 0, type: "rapid" },
            { z: .5, x: 0, type: "drilling" },
            { z: -depth, x: 0, type: "drilling" },
            { z: -depth, x: 0, type: "dwell", rotations: 3},
            { z: -depth, x: 0, type: "rapid" },
            { z: .5, x: 0, type: "rapid" }
        ]
        return inside.calculateTime(path, parameters)
    },
    insideTurning(parameters, vDimensions, valuesComplexity) {
        let inside_diameter = valuesComplexity.inside_diameter.value
        //  Region
        let region
        if (valuesComplexity.selected.value == "turning") region = [[0, -0.1], [0, 0.5], [-.8, 0.5], [-.8, 1]]
        else if (valuesComplexity.selected.value == "complex") region = [[0, -0.1], [0, 0.25], [-.6, 0.25], [-.6, 0.5], [-.8, 0.5], [-.8, 1]]
        region.forEach(point => {
            point[0] *= vDimensions.length.value * valuesComplexity.inside_depth.value/100
            point[1] *= vDimensions.diameter.value - inside_diameter
            point[1] += parseFloat(inside_diameter)
        });

        let path = contour.getContourPath(region, parameters)
        return inside.calculateTime(path, parameters)
    },

    calculateTime(path, parameters) {
        let time = { cut: 0, rapid: 0 }
        let n, vf
        let [f, vc] = [parameters.f, parameters.vc]
        let n_max = 4000
        let rapid = 400

        if (!path || !path.length) return 0
        path.forEach((v, vi) => {
            if (!vi) return
            vi = path[vi - 1]
            let distance = p.dist([v.x, v.z], [vi.x, vi.z])
            if (distance == undefined || isNaN(distance)) return
            if (v.type == "cut") {
                n = vc * 1000 / (Math.PI * Math.abs(v.x))
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "rapid") {
                time.rapid += distance / rapid
            }
            else if (v.type == "drilling") {
                n = vc * 1000 / (Math.PI * parameters.diameter)
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "partRotate") {
                time.rapid += (.5 / 360 * v.angle)
            }
            else if (v.type == "dwell") {
                n = vc * 1000 / (Math.PI * parameters.diameter)
                n > n_max ? n = n_max : ""
                time.cut += v.rotations / n * 60
            }
        });
        return time.cut + time.rapid
    }
}
var thread = {
    get(context, state, pState) {
        let time = 0
        // let valuesInside = state.calculation.processes[state.process_selected].subProcesses.inside.values
        let vThread = state.calculation.processes[state.process_selected].subProcesses.thread.values.thread.value
        let parameters = thread.getParameters(state.calculation.processes[state.process_selected].subProcesses.thread.values)
        let path
        vThread.forEach((t, i) => {
            t.diameter = parseFloat(t.diameter)
            t.length = parseFloat(t.length)
            if(i < vThread.length-1) time += 2
            //  Path
            path = []
            for (let c = 0; c < parameters.cuts; c++) {
                path.push({ z: .5, x: t.diameter, type: "rapid" })
                path.push({ z: .5, x: t.diameter, type: "cut" })
                path.push({ z: t.length, x: t.diameter, type: "cut" })
                path.push({ z: t.length, x: t.diameter + 2, type: "cut" })
                path.push({ z: .5, x: t.diameter + 2, type: "rapid" })
            }
            time += thread.calculateTime(path, parameters)
        });
        time *= context.getters.getTimeCorrectionFactor("threading")
        context.commit("addToManufactureChain", { time, tool: "threading", type: "threading" })
        if (vThread.length) pState.tools.threading = { parameters, time }
        // if (vThread.selected.value !== "") {
        //     let parameters = inside.getParameters(vComplexity)
        //     time += inside.drilling(vComplexity, vDimensions, parameters)
        //     if (vComplexity.selected.value === "turning" || vComplexity.selected.value === "complex") {
        //         console.log("medium");
        //         time += inside.insideTurning(parameters, vDimensions, vComplexity)
        //     }
        //     if (vComplexity.selected.value === "complex") {
        //         console.log("complex");
        //     }
        //     console.log(parameters);
        // }
        // else if (valuesInside.selected.value == "centering") {
        // let parameters = inside.getParameters(valuesComplexity)
        // time += sides.centering(parseInt(values.grooves_radial.value), parameters, diameter)

        // }
        return [parseFloat(time)]
    },
    getParameters(vThread) {
        let parameters = {
            cuts: 12,
            f: 1,
            vc: 120,
        }
        if (vThread.cuts && vThread.cuts.value) parameters.cuts = parseInt(vThread.cuts.value)
        if (vThread.f && vThread.f.value) parameters.f = parseInt(vThread.f.value)
        if (vThread.vc && vThread.vc.value) parameters.vc = parseInt(vThread.vc.value)
        return parameters
    },
    calculateTime(path, parameters) {
        let time = { cut: 0, rapid: 0 }
        let n, vf
        let [f, vc] = [parameters.f, parameters.vc]
        let n_max = 4000
        let rapid = 400

        if (!path || !path.length) return 0
        path.forEach((v, vi) => {
            if (!vi) return
            vi = path[vi - 1]
            let distance = p.dist([v.x, v.z], [vi.x, vi.z])
            if (distance == undefined || isNaN(distance)) return
            if (v.type == "cut") {
                n = vc * 1000 / (Math.PI * Math.abs(v.x))
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "rapid") {
                time.rapid += distance / rapid
            }
            else if (v.type == "drilling") {
                n = vc * 1000 / (Math.PI * this.parameters.diameter)
                n > n_max ? n = n_max : ""

                vf = f * n
                time.cut += distance / vf * 60
            }
            else if (v.type == "partRotate") {
                time.rapid += (.5 / 360 * v.angle)
            }
            else if (v.type == "dwell") {
                n = vc * 1000 / (Math.PI * this.parameters.diameter)
                n > n_max ? n = n_max : ""
                time.cut += v.rotations / n * 60
            }
        });
        return time.cut + time.rapid
    }
}
var eject = {
    get(context, state) {
        let time = 5
        if (state.calculation.processes[0] && state.calculation.processes[0].machine && state.calculation.processes[0].machine.eject_time) {
            time = state.calculation.processes[0].machine.eject_time
        }
        time *= context.getters.getTimeCorrectionFactor("eject")
        context.commit("addToManufactureChain", { time, type: "eject" })
        return [time]
    }
}

var p = {
    pointsArrayToObj(array) {
        let obj = []
        if (typeof (array) != "object") return array
        array.forEach(point => {
            obj.push({ z: point[0], x: point[1] })
        });
        return obj
    },
    pointsObjToArray(obj) {
        let array = []
        if (typeof (obj) != "object") return obj
        obj.forEach(point => {
            array.push([point.z, point.x])
        });
        return array
    },
    pointOnLine(p, v1, v2) {
        if (this.round(this.dist(p, v1) + this.dist(p, v2), 3) > this.round(this.dist(v1, v2), 3)) return 0
        return 1
    },
    round(val, amount) {
        return Math.round(val * 10 ** amount) / 10 ** amount
    },
    dist(p1, p2) {
        return ((p2[1] - p1[1]) ** 2 + (p2[0] - p1[0]) ** 2) ** 0.5
    },
    getPoint(v) {
        if (v == undefined) return -1
        if (v.x) {
            v = [v.x, v.y]
        }
        return v
    },
    vectorRise(p1, p2) {
        p1 = this.getPoint(p1)
        p2 = this.getPoint(p2)

        let rise = -(p2[1] - p1[1]) / (p2[0] - p1[0])
        rise == -Infinity ? rise = -9e10 : "";
        rise == Infinity ? rise = 9e10 : "";
        return rise
    },
    lineIntersect(p1, p2, v1, v2) {
        p1 = this.getPoint(p1)
        p2 = this.getPoint(p2)
        v1 = this.getPoint(v1)
        v2 = this.getPoint(v2)

        let m = []
        let r1 = -this.vectorRise(p2, p1)
        let r2 = -this.vectorRise(v2, v1)
        m[0] = (v1[1] - p1[1] + r1 * p1[0] - r2 * v1[0]) / (r1 - r2)
        m[1] = p1[1] + r1 * (m[0] - p1[0])
        return m
    },
    linesCrossing(v1, v2, p1, p2, threshold = 0.01) {
        /**
        * c:        center of line
        * v:        vector
        * p1, p2:   line
        */
        let int = p.lineIntersect(v1, v2, p1, p2)
        if (p.dist(int, v1) + p.dist(int, v2) > p.dist(v1, v2) + threshold) return 0
        if (p.dist(int, p1) + p.dist(int, p2) > p.dist(p1, p2) + threshold) return 0
        return 1
    },
}

import Vue from "vue";
const processes = {
    state: {
        executionStack: {
            turning: {
                contour: contour.get,
                sides: sides.get,
                grooves: grooves.get,
                inside: inside.get,
                thread: thread.get,
                eject: eject.get,
            }
        },
        manufactureChain: [],
        tools: {},
        times_corrected: {}
    },
    mutations: {
        setTime(state, [key, time]) {
            Vue.set(state.times, key, time)
        },
        time_correct(state, [type, value]) {
            Vue.set(state.times_corrected, type, value)
        },
        addToManufactureChain(state, object) {
            state.manufactureChain.push(object)
        }
    },
    actions: {
        processes_evaluate(context) {
            Vue.set(context.state, "manufactureChain", [])
            if (!context.rootState.calculation.processes[context.rootState.process_selected]) return
            let processType = context.rootState.calculation.processes[context.rootState.process_selected].type
            let stack = context.state.executionStack[processType]
            for (const key in stack) {
                stack[key](context, context.rootState, context.state) 
            }
        },
    },
    getters: {
        times: (state, getters, rootState) => {
            let timeData = []
            let lastTool = ""
            let changeTime = 4;
            if (rootState.calculation.processes[0] && rootState.calculation.processes[0].machine) changeTime = rootState.calculation.processes[0].machine.toolchange_time
            if (changeTime == "" || changeTime == undefined) changeTime = 0
            const evaluateSubProcess = (subProcess, lastTool, changeTime) => {
                if (subProcess.tool && lastTool && subProcess.tool != lastTool) {
                    timeData.push({ type: "toolchange", time: parseFloat(changeTime)})
                }
                lastTool = subProcess.tool
                timeData.push({ type: subProcess.type, time: subProcess.time })
            }

            state.manufactureChain.forEach(subProcess => {
                if (subProcess.parallel) {
                    subProcess.processes.forEach(parallelSP => {
                        if(!parallelSP.time) return
                        evaluateSubProcess(parallelSP, lastTool, changeTime)
                        lastTool = parallelSP.tool
                    });  
                    return                   
                }
                if (!subProcess.time) return
                evaluateSubProcess(subProcess, lastTool, changeTime)
                lastTool = subProcess.tool
            });

            return timeData
        },
        time: (state, getters) => {
            let time = 0
            getters.times.forEach(sP => {
                time += sP.time
            });
            return time
        },
        times_corrected: (state) => state.times_corrected,
        getTimeCorrectionFactor: (state) => (type) => {
            if (state.times_corrected[type]) return 1 + state.times_corrected[type]/100
            else return 1
        },
        tools_raw: (state) => state.tools
    }
}

export default processes