
import Vue from "vue";
import apiCall from '@/apiCall'
const material = {
    state: {
        materialInformation: {},
        materials: 0,
        information_raw: {},
        material_selected: "",
    },
    mutations: {
        // materialInformation_set(state, data) {
        //     state.materialInformation[data[0]] = data[1]
        // },
        materialInformation_change(state, data) {
            state.materialInformation[data[0]][data[1]] = data[2]
        },
        material_setInformation(state, [rootState, key, value]) {
            Vue.set(rootState.calculation.material, key, value)
        },
        material_changeSelected(state, [store, material, existing]) {
            Vue.set(state, "material_selected", material)
            if (!existing) return
            apiCall({
                method: "material_get",
                material
            }).then((response) => {
                if (response.statusCode == 200) {
                    for (const key in response.body) {
                        console.log(key, response.body[key]);
                        Vue.set(store.state.calculation.material, key, response.body[key])
                    }
                    store.dispatch("refresh")
                }
            })
        },
        // getMaterialInformation(state, material) {
        // },
    },
    actions: {
    },
    getters: {
        getMaterialInformation: (state, getters) => (material) => {
            if (state.materialInformation[material]) return state.materialInformation[material]
            const formData = new FormData();
            formData.append('method', "material_getInformation");
            formData.append('material_name', material);
            fetch(getters.path_data, {
                method: "POST",
                body: formData
            }).then(result => {
                return result.json()
            }).then(result => {
                Vue.set(state.materialInformation, material, result)
                Vue.set(state.information_raw, material, JSON.stringify(result))
            })
            return 0
        },
        materials: (state) => {
            if (state.materials) return state.materials
            apiCall({
                method: "material_getAll",
                material
            }).then(response => {
                console.log(response);
                if (response.statusCode == 200) {
                    Vue.set(state, "materials", response.body)
                    // for (const key in response.body) {
                    //     // console.log(key, response.body[key]);
                    //     // Vue.set(store.state.calculation.material, key, response.body[key])
                    // }
                }
            })
            // const formData = new FormData();
            // formData.append('method', "material_getNames");
            // fetch(getters.path_data, {
            //     method: "POST",
            //     body: formData
            // }).then(result => {
            //     return result.json()
            // }).then(result => {
            //     Vue.set(state, "materials", Object.keys(result))
            // })
            return 0
        },
        material_selected: (state) => state.material_selected,
        materialAttributes: (state, getters) => {
            if (state.materialAttributes) return state.materialAttributes
            const formData = new FormData();
            formData.append('method', "material_getAttributes");
            fetch(getters.path_data, {
                method: "POST",
                body: formData
            }).then(result => {
                return result.json()
            }).then(result => {
                Vue.set(state, "materialAttributes", result)
            })
            return 0
        },
        material_isCustomized: (state) => (material) => JSON.stringify(state.materialInformation[material]) != state.information_raw[material],
        material: (state, getters, rootState) => rootState.calculation.material,
    }
}

export default material




