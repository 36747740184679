<template>
    <div>
        <div id="gridContainer" class="card">
            <h3 class="card_title">Formfräsen</h3>
            <div class="padding" :class="{blue: sides == 1}" @click="setSides(1)">
                <img src="https://development.primecalc.de/assets/images/shapemilling.png" alt="">
                <p>Flächenfräsen</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            sides: 0
        }
    },
    methods: {
        setSides(count) {
            this.sides = count
        }
    }
}
</script>

<style scoped>
    #gridContainer{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2em;
    }
    img{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 6em;
        width: 6em;
        margin: .5em auto;
    }
    p{text-align: center;}
</style>