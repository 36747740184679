
// import Vue from "vue";
const setup = {
    state: {
        
    },
    mutations: {
        
    },
    actions: {
        
    },
    getters: {
                
    }
}

export default setup