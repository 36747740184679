<template>
    <div>
        <input type="text" placeholder="Suche" class="padding_small" v-model="searchterm">
    </div>
</template>

<script>
export default {
    name: "Search",
    props: ["values"],
    data() {
        return{
            searchterm: ""
        }
    },
    mounted() {
        console.log(this.values);
        this.$emit("filtered", this.values);
    },
    watch: {
        searchterm() {
            let newValues = []
            if (typeof this.values[0] != "object") {
                newValues = this.values.filter(val => val.toLowerCase().match(this.searchterm.toLowerCase()))
            }
            else {
                this.values.forEach(valObj => {
                    for (const key in valObj) {
                        if (String(valObj[key]).toLowerCase().match(this.searchterm.toLowerCase())) {
                            newValues.push(valObj)
                            return
                        }
                    }
                });
            }
            this.$emit("filtered", newValues);
        }
    }
}
</script>

<style>

</style>