<template>
    <div id="login_container">
        <amplify-authenticator @signedIn="test()"></amplify-authenticator>
        
        <!-- <div class="card">
            <h3 class="card_title padding_small dark radius">Login</h3>
            <form v-on:keyup.enter="login" class="padding">
                <p>Benutzername:</p>
                <input class="blue textspacing radius_small" placeholder="Benutzer" type="username" v-model="username">
                <p>Passwort:</p>
                <input class="blue textspacing radius_small" placeholder="Passwort" type="password" v-model="password">
                <br>
                <p>{{message}}</p>
                <button id="login_submit" class="blue padding_small radius_small" type="button" @click="login">Login</button>
            </form>
        </div> -->
    </div>
</template>

<script>
// import { AmplifyEventBus } from "aws-amplify-vue";
export default {
    name: "login",
    data() {
        return {
            username: "",
            password: "",
            message: "",
            checkLogin: true
        }
    },
    methods: {     
        test() {
            console.log("fioew");
        },   
        // login() {
        //     this.$Amplify.Auth.signIn(this.username, this.password).then(data => {
        //         // this.logger.info('sign in success');
        //         console.log(data);
        //         if (data.challengeName === 'SMS_MFA' || data.challengeName === 'SOFTWARE_TOKEN_MFA') {
        //             AmplifyEventBus.$emit('localUser', data);
        //             this.$store.commit("setLoggedIn", ["loggedIn", "confirmSignIn"]);
        //             return AmplifyEventBus.$emit('authState', 'confirmSignIn')
        //         } else if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
        //             AmplifyEventBus.$emit('localUser', data);
        //             this.$store.commit("setLoggedIn", ["loggedIn", "requireNewPassword"]);
        //             return AmplifyEventBus.$emit('authState', 'requireNewPassword');
        //         } else if (data.challengeName === 'MFA_SETUP') {
        //             AmplifyEventBus.$emit('localUser', data);
        //             this.$store.commit("setLoggedIn", ["loggedIn", "setMfa"]);
        //             return AmplifyEventBus.$emit('authState', 'setMfa');
        //         } else if (data.challengeName === 'CUSTOM_CHALLENGE' &&
        //             data.challengeParam &&
        //             data.challengeParam.trigger === 'true'
        //         ) {
        //             AmplifyEventBus.$emit('localUser', data);
        //             this.$store.commit("setLoggedIn", ["loggedIn", "customConfirmSignIn"]);
        //             return AmplifyEventBus.$emit('authState', 'customConfirmSignIn')
        //         } else {
        //             this.$store.commit("setLoggedIn", ["loggedIn", "signedIn"])
        //             return AmplifyEventBus.$emit('authState', 'signedIn')
        //         }
        //     }).catch((e) => {
        //         if (e.code && e.code === 'UserNotConfirmedException'){
        //             AmplifyEventBus.$emit('localUser', {username: this.signInUsername})
        //             AmplifyEventBus.$emit('authState', 'confirmSignUp')
        //         } else {
        //             if (e.message == "Incorrect username or password.") {
        //                 this.message = "Nutzername oder Passwort falsch."
        //             }
        //         }
        //     });
        // }
    },
    computed: {
        authorization() {
            return this.$store.getters.authorization
        }
    },
    async created() {
        this.$store.commit("setLoggedIn", ["loggedIn", "checkLogin"])  
        this.session = 0
        let thisObj = this;
        try {
            this.session = await this.$Amplify.Auth.currentSession()
            this.$store.commit("setLoggedIn", ["loggedIn", "signedIn"])            
        } catch (error) {
            this.$store.commit("setLoggedIn", ["loggedIn", "signIn"])     
            // thisObj.$commit("setLoggedIn", ["loggedIn", "signIn"])
            thisObj.checkLogin = false
        }
        

        // this.checkLogin = false
        // console.log("levwo");
        // console.log(this.session);
        // //  Get Login Data
        // console.log(this.$Amplify.Auth);
        // const formData = new FormData();
        // formData.append('method', "login_check");
        // fetch(this.$store.getters.path_account, {
        //     method: "POST",
        //     body: formData
        // }).then(result => {
        //     this.checkLogin = false;
        //     return result.json()
        // }).then(result => {
        //     if (result[0] === 1) {
        //         this.$store.dispatch("getSaves")
        //         this.$store.commit("setLoggedIn", ["username", result[1].username])
        //         this.$store.commit("setLoggedIn", ["company", result[1].company])  
        //         this.$store.commit("setLoggedIn", ["loggedIn", result[0]])
        //     }
        //     else {
        //         this.$router.push("/")
        //     }
        // }).catch(()=>{
        //     // if (this.$route.fullPath != "/") this.$router.push("/")
        // })
    }
}
</script>

<style scoped>

    body{
        background-color: #22252D;
    }
    input{
        outline: none;
        border: none;
        font-size: 125%;
    }
    #login_container>div{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #login_submit{
        width: fit-content;
        margin: auto;
        font-size: 125%;
    }

    form{
        display: grid;
        /* grid-template-rows: 1fr 1fr 1fr; */
    }

</style>

