<template>
    <div>
        <p><b>STP Datei einlesen</b></p>
        <img class="dark padding_small radius" src="https://primecalc.de/assets/icons/stp.svg" alt="">
        <img class="dark padding_small radius spacingleft" src="" alt="no STP">
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>