<template>
    <div id="loading" class="padding radius blue zMax">
        <div></div>
        <br>
        <p>{{message}}</p>
    </div>
</template>

<script>
export default {
    props: ["msg"],
    name: "loading",
    computed: {
        message() {
            if (this.msg) return this.msg
            else return "Lade..."
        }
    }
}
</script>

<style scoped>
    p{
        max-width: 12em;
        text-align: center;
    }
</style>