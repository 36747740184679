import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/new",
    name: "New",
    component: () => import("../views/New.vue")
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue")
  },
  {
    path: "/calculation/",
    name: "Calculation",
    component: () => import("../views/App.vue"),
    children: [
      {
        path: "material/",
        name: "Material",
        component: () => import("../views/calculation/Material.vue")
      },
      {
        path: "file/",
        name: "File",
        component: () => import("../views/calculation/File.vue")
      },
      {
        path: "machine/",
        name: "Machine",
        component: () => import("../views/calculation/Machine.vue")
      },
      {
        path: "processes/",
        name: "Processes",
        component: () => import("../views/calculation/Processes.vue"),
      },
      {
        path: "export/",
        name: "Export",
        component: () => import("../views/calculation/Export.vue")
      },
      {
        path: "calculation/",
        name: "Calculation",
        component: () => import("../views/calculation/Home.vue")
      },
      {
        path: "tools/",
        name: "Tools",
        component: () => import("../views/calculation/Tools.vue")
      },
      {
        path: "setup/",
        name: "Setup",
        component: () => import("../views/calculation/Setup.vue")
      },
      {
        path: "other/",
        name: "Other",
        component: () => import("../views/calculation/Other.vue")
      },
      {
        path: "feedback/",
        name: "Feedback",
        component: () => import("../views/calculation/Feedback.vue")
      }
    ]
  },
];

const router = new VueRouter({
  routes,
  // mode: 'history'
});

export default router;
