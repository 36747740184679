import Vue from "vue";
const apiCall = async (parameters) => {
    const session = await Vue.prototype.$Amplify.Auth.currentSession();
    const myInit = {
        mode: "no-cors",
        body: parameters,
        headers: {
            authorization: "Bearer " + session.getIdToken().getJwtToken()
        }
    }
    // return Vue.prototype.$Amplify.API.post("primecalc_clientAccess", "/prod", myInit).then(response => response)
    return await Vue.prototype.$Amplify.API.post("primecalc_clientAccess", "/prod", myInit).then(response => response)
}

export default apiCall