<template>
    <div id="box">
        <div id="subProcessOptions" class="card" v-if="subProcess_selected">
            <h3 class="card_title">Nötige Prozessparameter</h3>
            <div class="padding" id="dimensioning">
                <div id="gridContainer">
                    <div class="card pointer padding">
                        <img src="https://development.primecalc.de/assets/images/groove_0.png" alt="">
                        <counter :min="0" :step="1" :initial="values.grooves_radial" @change="changeValue('grooves_radial', $event)"></counter>
                        <p>Umlaufende Nut</p>
                    </div>
                    <div class="card pointer padding">
                        <img src="https://development.primecalc.de/assets/images/groove_1.png" alt="">
                        <counter :min="0" :step="1" :initial="values.grooves_contour" @change="changeValue('grooves_contour', $event)"></counter>
                        <p>Kontur stechen</p>
                    </div>
                    <div class="card pointer padding">
                        <img src="https://development.primecalc.de/assets/images/groove_2.png" alt="">
                        <counter :min="0" :step="1" :initial="values.grooves_axial" @change="changeValue('grooves_axial', $event)"></counter>
                        <p>Axiale Nut</p>
                    </div>
                </div>
                <p v-if="verify" @click="$store.dispatch('subProcesses_next')" class="blue padding_small radius fitContent pointer" style="margin: .5em 0 0 auto;">weiter</p>
            </div>
        </div>

        <div id="manufacturingtime" class="card">
            <h3 class="card_title">Prozess genauer definieren</h3>
            <div class="padding">
                <p class="textspacing"><b>Schnittparameter:</b></p>
                <div id="cuttingParameters" class="indent textspacing">
                    <div>
                        <p>Stechbreite w:</p>
                        <input type="number" :value="values.width" min="0.1" step=".1" @input="changeValue('width', $event.target.value)">
                        <p>mm</p>
                    </div>
                    <div>
                        <p>Vorschub f:</p>
                        <input type="number" :value="values.f" min="0" step="0.05" @input="changeValue('f', $event.target.value)">
                        <p>mm/U</p>
                    </div>
                    <div>
                        <p>Schnittgeschwindigkeit vc:</p>
                        <input type="number" :value="values.vc" min="0" step="1" @input="changeValue('vc', $event.target.value)">
                        <p>m/min</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        values() {
            let values = this.$store.getters.values("value")
            if (!values.width) this.changeValue("width", 2)
            if (!values.f) this.changeValue("f", .2)
            if (!values.vc) this.changeValue("vc", 150)

            return this.$store.getters.values("value")
        },  
        subProcess_selected() {
            return this.$store.state.subProcess_selected
        },
        verify() {
            return this.$store.getters.verify.processes[this.$store.state.process_selected].subProcesses[this.$store.state.subProcess_selected]
        },  
    },
    methods: {    
        changeValue(key, value) {
            if(key == "width" && value < 0.1) value = 0.1
            this.$store.commit("changeSubprocessValue", ["grooves", key, value]);
            this.$store.dispatch("refresh")
        }
    }
}
</script>

<style scoped>
    #box>.card:first-child{margin-bottom: 2em;}
    #box>.card{width: fit-content;}
    .card{
        transition: .25s;
    }
    #gridContainer{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 2em;
    }
    img{
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 8em;
        width: 8em;
        margin: 1em auto 0 auto;
    }
    #dimensioning>div>div>p{text-align: center;}

    #cuttingParameters>div{
        display: grid;
        grid-template-columns: auto 4em 4em;
        grid-column-gap: 1em;
        margin-bottom: 4px;
    }
</style>