<template>
  <div id="menu">
      <div id="menu_logo">
        <h1>PrimeCalc</h1>
        <p>{{$store.state.calculation.information.name}}</p>
      </div>
      <div id="menu_categories" v-if="categories">
            <router-link :to="'/calculation/'+c" class="button" :class="{blue: chosen == c}" v-for="c in categories" :key="c">
                <img :src="getImage(c)" alt="">
                <h2 style="margin: auto 0">{{dict(c)}}</h2>
                <p>{{$store.state.name}}</p>
            </router-link>
      </div>
      <div class="padding">
            <img @click="home()" class="pointer" src="https://kalkulation.primecalc.de/assets/images/icons/home.svg" alt="">
            <img @click="save()" :class="{muted: isSaved}" class="pointer" src="https://kalkulation.primecalc.de/assets/images/icons/save.svg" alt="">
            <img @click="load()" class="pointer" src="https://kalkulation.primecalc.de/assets/images/icons/load.svg" alt="">
            <div v-if="home_saveQuestion" class="padding dark radius">
                <p><b>Möchten Sie vorher speichern?</b></p>
                <div class="flex">
                    <p @click="save(1)" class="pointer">Ja</p>
                    <p @click="home(1)" class="pointer" style="margin-left: 1em">Nein</p>
                </div>
            </div>
      </div>
      <div id="menu_company" class="padding dark">
          <div>
            <img class="invert pointer" @click="logout()" src="https://dashboard.pixelprime.de/assets/icons/light/logout.svg" alt="">
            <div>
                <p class="padding_small">{{company}}</p>
                <p class="padding_small">{{username}}</p>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "pcMenu",
        data() {
            return {
                categories: [],
                home_saveQuestion: false,
                loading: false,
                error: null,
                post: null,
            }
        },
        created () {
            // fetch the data when the view is created and the data is
            // already being observed
            this.getCategories()
        },
        computed: {
            company() {
                return this.$store.state.company || ""
            },
            username() {
                return this.$store.state.username || ""
            },
            chosen() {
                this.$store.commit("setVerifyOpened", this.$route.name.toLowerCase())
                return this.$route.name.toLowerCase()
            },
            isSaved() {
                return this.$store.getters.isSaved
            }
        },
        methods: {
            getCategories () {
                this.error = this.post = null
                this.loading = true

                const formData = new FormData();
                formData.append('method', "menu_getCategories");
                fetch(this.$store.getters.path_data, {
                    method: "POST",
                    body: formData
                }).then(result => {
                    return result.json()
                }).then(result => {
                    this.categories = result
                    this.loadingData = 0
                })
            },
            getImage(c) {
                return `https://kalkulation.primecalc.de/assets/images/icons/${c}.svg`
            },
            getLink(c) {
                return `/${c}`
            },
            dict(c) {
                return this.$store.state.dict[c] || c
            },
            home(force=0) {
                this.$store.dispatch("generateSave")
                if(!force && !this.isSaved) {
                    this.home_saveQuestion = true
                }
                else {
                    this.$router.push('/')
                    this.$store.dispatch("unloadCalculation")
                }
                // this.lastSave = this.$store.state.save_data
            },
            save(alsoHome=0) {
                this.$store.dispatch("generateSave")
                this.$store.dispatch("save")
                // this.lastSave = this.$store.state.save_data
                if(alsoHome) this.home()
            },
            load(force=0) {
                this.$store.dispatch("generateSave")
                if(!force && !this.isSaved) {
                    this.home_saveQuestion = true
                }
                else this.$router.push('/')
            },
            logout() {
                this.$store.dispatch("logout", { vm: this });
            }
        }
    }
</script>

<style scoped>
    .menu_category{
        display: grid;
        grid-template-columns: 32px 1fr;
        text-decoration: none;
    }
    .menu_category>img{
        height: 24px;
        width: 24px;
    }
    .menu_category>h2{
        margin: auto;
    }
    #menu_categories>a{
        transition: .25s;
        background-color: #f8f8f8;
    }

    #menu_logo>p{
        position: absolute;
        margin: auto;
        top: calc(50% + 20pt);
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #menu_categories>a{
        text-decoration: none;
        padding: .75em 2em;
        border-radius: 50%;
        border-radius: 1em;   
        margin-bottom: 4px;     
    }
    #menu_categories>a{
        display: grid;
        grid-template-columns: 32px 1fr;
    }
    #menu_categories>a>img{
        height: 24px;
        width: 24px;
    }

    #menu_company>div{
        margin: auto;
        display: grid;
        grid-template-columns: auto auto;
    }
    #menu_company>div>img{margin: auto;}
</style>



