<template>
    <div :class="colorCondition" :title="title"></div>
</template>

<script>
export default {
    name: "indicator",
    props: ["condition", "required", "ttle", "forcegray"],
    computed: {
        title() {
            if(this.ttle) return this.ttle
            return this.condition ? "Erledigt" : "Noch nicht erledigt"
        },
        colorCondition() {
            if(this.forcegray) return "gray"
            if(this.condition != 0) return "green"
            else if (this.required) return this.condition != 0 ? "green" : ""
            return "gray"
        }
    }
}
</script>

<style scoped>
    div{
        display: inline-block;
        border-radius: 50%;
        width: .5em;
        height: .5em;
        background-color: rgb(255, 174, 149);
        margin: auto .25em auto 0;
        transition: .25s;
    }
    .green{background-color: rgb(100, 173, 100);}
    .gray{background-color: rgb(211, 211, 211);}
</style>